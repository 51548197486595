<!--Hotels -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <ng-container *ngIf="hotel?.detail as hotelDetail">
      <div [class.duplicate-item-wrapper]="duplicateBooking">
        <div *ngIf="duplicateBooking" class="duplicate-item-date">
          <i class="ico-bed"></i>&nbsp;
            <span i18n="@@basketPanelCheckInHotels">Check-In:</span>
            <div class="cart_item_hotel_dates" *ngFor="let room of hotelDetail?.rooms">
              <div *ngIf="room.checkIn && room.checkOut">
                <strong *ngIf="room.checkIn as checkIn">{{ checkIn | sabsDate: 1 }}</strong>
              </div>
            </div>
        </div>
        <div [class.duplicate-item-info]="duplicateBooking">
          <div class="cart_item_heading"
              *ngIf="hotelDetail.hotelDescription as hotelDescription">
            <ng-container *ngIf="!duplicateBooking">
              <i class="ico-bed"></i>&nbsp;
            </ng-container>
            <span>{{ hotelDescription }}</span>
            <button *ngIf="!duplicateBooking"
                    class="cart_item_remove ico-trash"
                    ngbTooltip="Remove  {{ hotelDescription }} from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                    attr.aria-label="Remove  {{ hotelDescription }} hotel from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                    i18n-ngbTooltip
                    (click)="remove.emit()"></button>
            <img *ngIf="!duplicateBooking"
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/basket_move.svg"
                class="move_basket_icon"
                role="button"
                ngbTooltip="Move to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                i18n-ngbTooltip
                attr.aria-label="Move  {{ hotelDescription }} hotel to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                (click)="openChangeModal.emit()"
                (keydown.enter)="openChangeModal.emit()"
                (keydown.space)="openChangeModal.emit()"
                tabindex="0">
          </div>
          <div class="cart_item_hotel_name"
              *ngIf="hotelDetail.hotelName as hotelName">
            {{ hotelName }}
          </div>
          <div class="cart_item_hotel_room_type"
              *ngIf="hotelDetail?.roomType as roomType">
            <span i18n="@@basketPanelRoomType">Room Type:</span>
            {{ roomType }}
          </div>
          <div class="cart_item_hotel_room_type"
              *ngIf="hotelDetail?.numberOfRooms as numberOfRooms">
            <span i18n="@@basketPanelNoOfRooms">No of Rooms:</span>
            {{ numberOfRooms }}
          </div>
          <div class="cart_item_hotel_location" *ngIf="hotelDetail?.hotelAddress as hotelAddress">{{ getHotelAddress() }}</div>
          <ng-container *ngIf="!duplicateBooking">
            <div class="cart_item_hotel_dates" *ngFor="let room of hotelDetail?.rooms">
              <div *ngIf="room.checkIn && room.checkOut">
                <ng-container *ngIf="room.checkIn as checkIn">{{ checkIn | sabsDate }}</ng-container>&nbsp;-&nbsp;
                <ng-container *ngIf="room.checkOut as checkOut">{{ checkOut | sabsDate }}</ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="cart_item_price"
         *ngIf="hotel.price >= 0">
         <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostHotels">Cost</strong>
      <strong>{{ hotel.price | currency: hotel.currency }}</strong>
      <span class="converted_currency"
            *ngIf="hotel.convertedCurrencyCode">&nbsp;(Approx: {{ hotel.convertedCurrencyAmount | currency: hotel.convertedCurrencyCode }})</span>
      <em *ngIf="!duplicateBooking" i18n="@@basketPanelTotalPriceHotels">&nbsp;Total Price</em>
    </div>
  </div>
</div>
