<!--
	Lightning Style Logon Page
	Desc: A logon page mimicking the logon page for lightning
	Author: Ed Stansfield
	Date: 11/12/2017
-->
<ng-container *ngIf="themeService.isLightningEnv">
  <div class="login_container">
    <div class="two_third">
      <h1 class="login_welcome">
        <span i18n="@@logonWelcomeToLightning">Welcome to Lightning,</span>
        <span i18n="@@logonPleaseLogIn">please log in.</span>
      </h1>
      <div class="bg_login_page">
        <section class="login_panel">
          <img
            class="lightning_logo"
            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo.svg"
            alt="Lightning logo" />
          <div role="alert" class="error" *ngIf="login_error">
            <div id="error_text_element" class="login_error_msg">
              {{ error_message }}
              <a
                class="error_dismiss"
                tabindex="0"
                (click)="closeErrors()"
                (keydown.enter)="closeErrors()"
                (keydown.space)="closeErrors()"
                aria-label="Close Errors"
                role="button">
                <i class="ico-cancel"></i>
              </a>
            </div>
          </div>
          <form action="javascript:void(0);" autocomplete="off" (ngSubmit)="login()">
            <div class="formRow">
              <label for="login-userName" i18n="@@logonUserName">User name</label>
              <input
                autocomplete="off"
                [(ngModel)]="user_name"
                id="login-userName"
                name="userName"
                type="text"
                [ngClass]="{ 'login-error': username_error }"
                (ngModelChange)="username_error = false" />
            </div>
            <div class="formRow">
              <label for="login-password" i18n="@@logonPassword">Password</label>
              <input
                autocomplete="off"
                [type]="inputType"
                [(ngModel)]="pass_word"
                id="login-password"
                name="password"
                [ngClass]="{ 'login-error': password_error }"
                (ngModelChange)="password_error = false" />
              <fa-icon
                (click)="togglePasswordVisibility()"
                [icon]="showPassword ? faEyeSlash : faEye"
                [attr.aria-label]="showPassword ? 'Hide password' : 'Show password'"
                role="button"
                (keydown.enter)="togglePasswordVisibility()"
                (keydown.space)="togglePasswordVisibility()"
                tabindex="0"
                class="fa fa-eye"></fa-icon>
            </div>

            <div class="form_login_actions">
              <button
                [disabled]="loading"
                [attr.aria-busy]="loading"
                type="submit"
                id="login-button"
                class="login_submit"
                aria-labelledby="aria-login">
                <span *ngIf="!loading"
                  ><span i18n="@@logonLogIn">Log in</span>
                  <i class="ico-chevron-right" aria-hidden="true"></i>
                </span>
                <span *ngIf="loading">
                  <i class="button_spinner"></i>
                </span>
                <span id="aria-login" hidden i18n="@@AriaLoginButton">Login</span>
              </button>
              <button
                class="recover_password_button"
                type="button"
                id="login-button-recover-password"
                id="recover-password-button"
                (click)="recoverPassword()"
                aria-labelledby="aria-recover-password">
                <span i18n="@@logonRecoverPassword">Recover Forgotten Password</span>
                <span id="aria-recover-password" hidden i18n="@@AriaRecoverPassword"
                  >Recover forgotten password</span
                >
              </button>
            </div>

            <a
              target="_blank"
              class="wcag_dac_logo"
              title="Accessibility statement"
              href="https://digitalaccessibilitycentre.org/index.php/CTM-Lightning-Enterprise/"
              aria-label="Accessibility Statement">
              <img
                src="https://images.sabscorp.com/images/logo/DAC_Certification_Two_Tick.jpg"
                alt="DAC Certification, opens in new tab" />
            </a>
            <div class="version" aria-label="software version number">
              <span i18n="@@logonVersion" id="version-number">Version</span>: {{ currentVersion }}
            </div>
          </form>
          <div class="ssomessage" *ngIf="hasSSOMessage">
            <p i18n="@@loginSSOMessage1">
              Please access Lightning via your organisations' Single Sign-On Solution
            </p>
            <p i18n="@@loginSSOMessage2">
              If you need assistance, please contact your Travel Team.
            </p>
            <br />
            <a *ngIf="showDigitsURL" href="https://travel.crowncommercial.gov.uk/ "
              >https://travel.crowncommercial.gov.uk/
            </a>
          </div>
        </section>
      </div>
    </div>
    <div class="one_third">
      <div class="announcements" *ngIf="loginAnnouncements && !isEmptyObject(loginAnnouncements)">
        <div class="announcement" *ngFor="let key of loginAnnouncements | keyValue">
          <h2 class="announcement_header" *ngIf="key.key !== 'parking_lo'">
            {{ key.key | titlecase }} <span i18n="@@logonNews">News</span>
          </h2>
          <h2
            class="announcement_header"
            *ngIf="key.key === 'parking_lo'"
            i18n="@@logonParkingLoungesNews">
            Parking and Lounges News
          </h2>
          <ul class="announcements_list" *ngIf="key.value">
            <ng-container *ngFor="let announcement of key.value | keyValue">
              <ng-container *ngIf="announcement.value.length">
                <li *ngFor="let ann of announcement.value">
                  {{ ann.message }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="themeService.isScionEnv">
  <div class="scion-logon-component">
    <div class="login-container">
      <section class="login-panel">
        <div class="login-container-mobile">
          <div class="welcome-header-container">
            <span class="welcome-header"> Welcome, </span>
            <span class="welcome-header-2"> please log in </span>
          </div>
          <div *ngIf="login_error" class="login-panel__error">
            <span role="alert" aria-live="assertive" tabindex="0" id="logon-validation-error">{{
              error_message
            }}</span>
            <a
              tabindex="0"
              (keydown.enter)="closeErrors()"
              (keydown.space)="closeErrors()"
              (click)="closeErrors()"
              role="button"
              id="close_validation_scion"
              aria-label="Close validation messages"
              class="login-panel__error-dismiss close-validation-x">
              <fa-icon [icon]="['fas', 'times']" class="fa-lg"></fa-icon>
            </a>
          </div>
          <form action="javascript:void(0);" autocomplete="off" (ngSubmit)="login()">
            <label class="header-label" for="logon-input-username">Username</label>
            <div class="flex-row-container login-panel__fields">
              <input
                autocomplete="off"
                [(ngModel)]="user_name"
                id="logon-input-username"
                name="userName"
                type="text"
                class="input-box"
                placeholder="Enter username here..."
                aria-required="true" />
            </div>
            <label class="header-label" for="logon-input-password">Password</label>
            <div class="flex-row-container login-panel__fields">
              <input
                autocomplete="off"
                [(ngModel)]="pass_word"
                id="logon-input-password"
                name="password"
                [type]="inputType"
                class="input-box"
                placeholder="Enter password here..."
                aria-required="true" />
              <fa-icon
                (click)="togglePasswordVisibility()"
                [icon]="showPassword ? faEyeSlash : faEye"
                [attr.aria-label]="showPassword ? 'Hide password' : 'Show password'"
                role="button"
                tabindex="0"
                id="password_visibility_scion"
                (keydown.enter)="togglePasswordVisibility()"
                (keydown.space)="togglePasswordVisibility()"
                class="fa-eye"></fa-icon>
            </div>
            <div class="recover-password-holder">
              <a
                (click)="recoverPassword()"
                class="login-panel__anchor"
                id="login-link-recoverpassword"
                tabindex="0">
                <i class="material-icons"> vpn_key </i>
                <span>Recover password </span>
              </a>
            </div>
            <button
              [disabled]="loading"
              type="submit"
              id="login-button-submit"
              class="login-panel__button">
              <span [ngClass]="{ 'invisible-login': loading }"> Log in </span>
              <img
                class="login-loader"
                src="https://images.sabscorp.com/images/sabsscion/btd/btd-gif.gif"
                alt="SABS Scion"
                *ngIf="loading" />
            </button>

            <button id="login-button-sso" class="login-panel__button" (click)="authSso()">
              SSO
            </button>

            <div class="login-footer">Version: {{ currentVersion }}</div>
          </form>
        </div>
      </section>
      <div class="login-side-container">
        <div class="login-popup">
          <div class="welcome-header-container">
            <span class="welcome-header"> Welcome, </span>
            <span class="welcome-header-2"> please log in </span>
          </div>
          <div *ngIf="login_error" class="login-panel__error">
            <span role="alert" aria-live="assertive" tabindex="0" id="logon-validation-error">{{
              error_message
            }}</span>
            <a
              tabindex="0"
              (keydown.enter)="closeErrors()"
              (keydown.space)="closeErrors()"
              (click)="closeErrors()"
              role="button"
              id="scion_close_validation"
              aria-label="Close validation messages"
              class="login-panel__error-dismiss close-validation-x">
              <fa-icon [icon]="['fas', 'times']" class="fa-lg"></fa-icon>
            </a>
          </div>
          <form action="javascript:void(0);" autocomplete="off" (ngSubmit)="login()">
            <label class="header-label" for="userName">Username</label>
            <div class="flex-row-container login-panel__fields">
              <input
                autocomplete="off"
                [(ngModel)]="user_name"
                id="userName"
                name="userName"
                type="text"
                class="input-box"
                id="scion_username"
                placeholder="Enter username here..."
                aria-required="true" />
            </div>
            <label class="header-label" for="password">Password</label>
            <div class="flex-row-container login-panel__fields">
              <input
                autocomplete="off"
                [(ngModel)]="pass_word"
                id="password"
                name="password"
                [type]="inputType"
                id="scion_password"
                class="input-box"
                placeholder="Enter password here..."
                aria-required="true" />
              <fa-icon
                (click)="togglePasswordVisibility()"
                [icon]="showPassword ? faEyeSlash : faEye"
                [attr.aria-label]="showPassword ? 'Hide password' : 'Show password'"
                role="button"
                tabindex="0"
                id="scion_show_password"
                (keydown.enter)="togglePasswordVisibility()"
                (keydown.space)="togglePasswordVisibility()"
                class="fa fa-eye"></fa-icon>
            </div>
            <div class="recover-password-holder">
              <a
                (click)="recoverPassword()"
                class="login-panel__anchor"
                tabindex="0"
                id="scion_recover_password">
                <i class="material-icons"> vpn_key </i>
                <span>Recover password </span>
              </a>
            </div>
            <button [disabled]="loading" type="submit" id="scion_login" class="login-panel__button">
              <span [ngClass]="{ 'invisible-login': loading }"> Log in </span>
              <span *ngIf="loading">
                <i class="login-loader button_spinner"></i>
              </span>
            </button>
            <button id="login-button-sso" class="login-panel__button sso" (click)="authSso()">
              SSO
            </button>
            <div class="login-footer">Version: {{ currentVersion }}</div>
          </form>
          <img
            class="wcag_dac_logo wcag_logo"
            src="https://images.sabscorp.com/images/logo/wsag.jpg"
            alt="WCAG Certification" />
        </div>
      </div>
    </div>
  </div>
</ng-container>
