import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketItem, User } from "@sabstravtech/obtservices/angular";
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';

@Component({
  templateUrl: "./duplicate-booking-modal.component.html",
  styleUrls: ["./duplicate-booking-modal.component.scss"],
})
export class DuplicateBookingModalComponent implements OnInit {
  
  userBooking: BasketItem[] = null;
  displayItinerary: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void { 
    console.log(this.userBooking);
   }
}

LightningModalTypes.ModalDuplicateBookingComponent.component = DuplicateBookingModalComponent;
