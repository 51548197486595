<div *ngIf="loaded"
     class="carbon_allowance_container">
   <div class="flex-row-container flex-row-container--between"
        *ngIf="policy">
      <h4>
         <i class="ico-person"
            *ngIf="policy.carbonPolicySource === CarbonPolicySource.User"></i>
         <i class="ico-hotel"
            *ngIf="policy.carbonPolicySource === CarbonPolicySource.Office"></i>
         <i class="ico-hotel"
            *ngIf="policy.carbonPolicySource === CarbonPolicySource.Company"></i>
         <i class="ico-hotel"
            *ngIf="policy.carbonPolicySource === CarbonPolicySource.Division"></i>
         {{policyHolder}}
      </h4>
      <h4><span i18n="@@CO2DateRange">Date Range</span>: {{startOfPeriod}} - {{endOfPeriod}}</h4>
   </div>
   <div *ngIf="policy"
        class="report-container">
      <div class="progress-report">
         <div class="progress-bar progress-bar-striped bg-success bg-danger"
              role="progressbar"
              [attr.aria-valuenow]="calculatedRemaingCo2Percentage"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width:100%"
              aria-valuetext="Remaining CO2 Allowance">
            <span class="show_text" *ngIf="calculatedRemaingCo2Percentage === remainingCo2Percentage">{{remainingCo2Percentage}}%
               ({{policy.remainingCo2}}kg/{{policy.totalCo2}}kg)</span>
               <span class="show_text" *ngIf="calculatedRemaingCo2Percentage !== remainingCo2Percentage"><span i18n="@@CO2AdjustedWithBasket">Adjusted with {{ displayItinerary ? 'itinerary' : 'basket'}}</span> {{calculatedRemaingCo2Percentage}}%
               ({{adjustedTotal}}kg/{{policy.totalCo2}}kg)</span>
         </div>
      </div>

      <!-- Overlay bar for when we can select bookings with carbon -->
      <!-- TODO - implement a listener to update this based off of items in the basket -->
      <!--  -->
      <div class="progress-report adjusted-report"
           *ngIf="calculatedRemaingCo2Percentage > 0">
         <div class="progress-bar progress-bar-striped bg-success"
              role="progressbar"
              [attr.aria-valuenow]="calculatedRemaingCo2Percentage"
              aria-valuemin="0"
              aria-valuemax="100"
              style="width:{{calculatedRemaingCo2Percentage}}%"
              aria-valuetext="Remaining CO2 Allowance Adjusted with {{ displayItinerary ? 'Itinerary' : 'Basket'}}">
         </div>
      </div>
      <!-- <p><ngb-progressbar class="progress-report" type="success" [showValue]="true" [height]="'3rem'" [value]="carbonAllowancePolicy.remainingCo2" [max]="carbonAllowancePolicy.totalCo2" [striped]="true"></ngb-progressbar></p> -->
   </div>
   <p class="co2_item_container" *ngIf="co2PerItem >= 0"><span i18n="@@co2_message_1">The current {{ displayItinerary ? 'itinerary' : 'basket'}} item uses</span> <span>&nbsp;{{co2PerItem}}</span>&nbsp;<span i18n="@flight_kg">kg</span>
      <span i18n="@flight_leg_co2">&nbsp;CO</span><sub i18n="@flight_leg_2">2</sub>
      <span i18n="@@co2_message_2">.&nbsp;This accounts for</span><span>&nbsp;{{calculatePercentage()}}%<span i18n="@@co2_message_3"> of the allowance for this period.</span></span>
   </p>
</div>