import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { UserService } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-approval-warning-modal',
  templateUrl: './approval-warning-modal.component.html',
  styleUrl: './approval-warning-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalWarningModalComponent {

  displayItinerary = false;

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService
  ) {
    this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
  }

  async closeModal(userOk: boolean): Promise<void> {
    this.activeModal.close(userOk);
  }

}
LightningModalTypes.ApprovalWarningModalComponent.component = ApprovalWarningModalComponent;