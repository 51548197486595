<ng-template #rt let-r="result" let-t="term">
  <span>
    {{ r.name }} <span *ngIf="r.countryCode">({{ r.countryCode }})</span></span
  >
</ng-template>
<ng-template #rtp let-r="result" let-t="term">
  <span> {{ r.name }}</span>
</ng-template>

<ng-container
  *ngIf="{
    location_types: (searchParams.location_types | async) || [],
    offices: (searchParams.offices | async) || []
  } as data">
  <div class="inputs-container" id="hotelSearchForm">
    <mat-radio-group
      [class]="data.location_types.length == 5 ? 'quin-radio-buttons' : 'quad-radio-buttons'"
      [(ngModel)]="searchParams.location_type_select"
      (ngModelChange)="hotelLocationChange($event)"
      aria-labelledby="hotel-location-selection">
      <mat-radio-button
        class="light-primary"
        id="hotel-location-select-{{type | removeSpaces}}"
        color="primary"
        [value]="type"
        *ngFor="let type of data.location_types">
        {{ type | translate }}
      </mat-radio-button>
    </mat-radio-group>
    <label id="hotel-location-selection" class="visuallyhidden" i18n="@@HotelLocationSelection">Location Selection</label>
    <div class="half-container">
      <div class="half-block">
        <label
          class="header-label"
          *ngIf="
            searchParams.location_type_select !== LocationTypes.City &&
            searchParams.location_type_select !== LocationTypes.Shortlist && 
            searchParams.location_type_select !== LocationTypes.TrainStation
          "
          id="locationssearch"
          i18n="@@hotelSearchLocation"
          >Location</label
        >

        <app-search-country
          *ngIf="searchParams.location_type_select === LocationTypes.City"
          [(country)]="searchParams.country"
          [countries]="searchService.countries"
          (countryChange)="countryChange($event)"
          title="Country"
          i18n-title>
        </app-search-country>

        <div *ngIf="searchParams.location_type_select === LocationTypes.Office">
          <i class="ico-hotel select-box-icon" aria-hidden="true"></i>
          <select
            class="select-box"
            id="hotel-input-office"
            [(ngModel)]="searchParams.office"
            aria-labelledby="hotel-office-location-search"
            [compareWith]="compareOffice">
            <option value="null" disabled selected i18n="@@hotelSearchSelectOffice">
              Select an Office
            </option>
            <option *ngFor="let office of officesList" [ngValue]="office">
              {{ office.name }}
            </option>
          </select>

          <span id="hotel-office-location-search" hidden i18n="@@AriaHotelOfficeLocationSearch"
            >Select an office</span
          >
        </div>

        <app-search-flight-location-typeahead
          *ngIf="searchParams.location_type_select === LocationTypes.Airport"
          [(model)]="searchParams.location"
          [country]="country"
          (updateElement)="ensureElementIsScrolledTo($event)">
        </app-search-flight-location-typeahead>

        <app-search-rail-location-typeahead
          *ngIf="searchParams.location_type_select === LocationTypes.TrainStation"
          [(model)]="searchParams.trainsStation"
          [country]="country"
          (modelChange)="searchParams.location = null"
          (updateElement)="ensureElementIsScrolledTo($event)"
          [isTrain]="trainIcon">
        </app-search-rail-location-typeahead>

        <!-- Department's choice of preferred CHP properties -->
        <app-search-country
          *ngIf="searchParams.location_type_select === LocationTypes.Shortlist"
          [(country)]="searchParams.country"
          [countries]="searchService.countries"
          (countryChange)="countryChange($event)"
          title="Country"
          i18n-title>
        </app-search-country>

        <!-- Department's choice of preferred CHP properties -->
      </div>
      <div class="half-block">
        <div class="two-thirds-container">
          <ng-container *ngIf="searchParams.location_type_select === LocationTypes.City">
            <div class="two-third">
              <label class="header-label" i18n="@@hotelSearchLocation" for="hotel-pref-loc"
                >Location</label
              >
              <i class="ico-marker select-box-icon" aria-hidden="true"></i>
              <div class="flex-row-container">
                <input
                  autocomplete="off"
                  i18n-placeholder
                  placeholder="Town/City"
                  type="text"
                  class="input-box"
                  id="hotel-pref-loc"
                  onClick="this.select()"
                  [(ngModel)]="searchParams.location"
                  [ngbTypeahead]="searchLocations"
                  [resultTemplate]="rt"
                  [inputFormatter]="formatter"
                  (focus)="focus$.next($event.target.value)"
                  [editable]="false"
                  aria-labelledby="hotel-city-location-search"
                  [ngClass]="{
                    'error-box': searchParams.validationMessages?.includes(
                      'Please select a hotel location or enter a postcode.'
                    )
                  }"
                  (ngModelChange)="
                    searchParams.locationPostcode_error = false; forcePriority($event)
                  "
                  (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                  (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
                <!-- <i *ngIf='searchService.tempDestArray[10].loading'
                   class='button_spinner input-loading-spinner-right'></i> -->
                <span id="hotel-city-location-search" hidden i18n="@@AriaHotelCityLocationSearch"
                  >Select a city</span
                >
              </div>
            </div>
            <!-- show postcode for UK only -->
            <div class="one-third">
              <label class="header-label" i18n="@@hotelSearchPostcode" for="hotel-postcode-typeahead"
                >Postcode</label
              >
              <i class="ico-marker input-box-icon" aria-hidden="true"></i>
              <input
                autocomplete="off"
                i18n-placeholder
                placeholder="Postcode"
                type="text"
                class="input-box"
                id="hotel-postcode-typeahead"
                [ngbTypeahead]="searchPostCode"
                [(ngModel)]="searchParams.postcode"
                [resultTemplate]="rtp"
                onClick="this.select()"
                (focus)="focus$.next($event.target.value)"
                [inputFormatter]="postCodeFormatter"
                [editable]="false"
                aria-labelledby="aria-hotel-search-postcode"
                [ngClass]="{
                  'error-box': searchParams.validationMessages?.includes(
                    'Please select a hotel location or enter a postcode.'
                  )
                }"
                (ngModelChange)="
                  searchParams.locationPostcode_error = false; forcePriority($event)
                " />
              <span id="aria-hotel-search-postcode" hidden i18n="@@AriaHotelSearchPostcode"
                >Postcode</span
              >
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="half-container">
      <div class="half-block">
        <div class="half-container">
          <div class="half-block">
            <label class="header-label date-label" id="checkinlabel" for="hotel-checkin-date" i18n="@@hotelSearchCheckIn"
              >Check In (DD/MM/YYYY)</label
            >
            <div
              [ngClass]="{
                'error-box': searchParams.validationMessages?.includes(
                  'Checkin date must not be in the past'
                )
              }">
              <ngbd-datepicker-i18n>
                <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                <app-datepicker
                  [minDate]="searchParams.min_date"
                  [startDate]="searchParams.checkin_date"
                  [model]="searchParams.checkin_date_ngb"
                  [class]="'combo-box'"
                  [componentId]="'hotel-checkin-date'"
                  (newDate)="searchParams.checkin_date_ngb = $event">
                </app-datepicker>
              </ngbd-datepicker-i18n>
            </div>
          </div>
          <div class="half-block">
            <label class="header-label date-label" id="checkoutlabel" for="hotel-checkout-date" i18n="@@hotelSearchCheckOut"
              >Check Out (DD/MM/YYYY)
            </label>
            <ngbd-datepicker-i18n>
              <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
              <app-datepicker
                [minDate]="searchParams.checkin_date_ngb"
                [startDate]="searchParams.checkout_date"
                [model]="searchParams.checkout_date_ngb"
                [class]="'combo-box'"
                [componentId]="'hotel-checkout-date'"
                (newDate)="searchParams.checkout_date_ngb = $event">
              </app-datepicker>
            </ngbd-datepicker-i18n>
          </div>
        </div>
      </div>
      <div class="half-block">
        <div class="two-thirds-container">
          <div class="two-third">
            <div class="half-container">
              <div class="half-block">
                <label
                  class="header-label"
                  id="within"
                  for="hotel-within-select"
                  i18n="@@hotelSearchWithinDistance"
                  >Within distance</label
                >
                <select
                  class="distance-select"
                  [(ngModel)]="searchParams.distance"
                  aria-labelledby="within"
                  id="hotel-within-selec"
                  class="select-box select-box--no-icon">
                  <option [ngValue]="value" *ngFor="let value of searchParams.distance_list">
                    {{ value | switchDistanceFormat: userDistanceUnit:true | number: '1.0-0' }}
                    <ng-container *ngIf="userDistanceUnit === 'Miles'">
                      <span *ngIf="value === 1" i18n="@@hotelSearchMile">mile</span>
                      <span *ngIf="value > 1" i18n="@@hotelSearchMiles">miles</span>
                    </ng-container>
                    <span
                      *ngIf="userDistanceUnit !== 'Miles'"
                      i18n="@@hotelSearchDistanceTypes"
                      class="distance_unit"
                      >{{ userDistanceUnit | lowercase }}</span
                    >
                  </option>
                </select>
              </div>
              <div class="half-block">
                <label
                  class="header-label"
                  id="noofrooms"
                  for="hotel-input-noofrooms"
                  i18n="@@hotelSearchNoOfRooms"
                  >No of Rooms</label
                >
                <i class="ico ico-new-user input-box-icon"></i>
                <input
                  type="number"
                  name="room_no"
                  min="1"
                  step="1"
                  max="{{ limitRoomCountPerTraveller() }}"
                  [roomNoValidate]="limitRoomCountPerTraveller()"
                  [(ngModel)]="searchParams.no_of_rooms"
                  i18n-placeholder
                  placeholder="Enter Number of Rooms."
                  aria-labelledby="noofrooms"
                  class="input-box"
                  id="hotel-input-noofrooms"
                  [ngClass]="{
                    'error-box': searchParams.validationMessages?.includes(
                      'Occupants per room must be 1 or 2; if you have an odd number of travellers (greater than one); the no of rooms must be equal'
                    )
                  }"
                  (ngModelChange)="searchParams.rooms_error = false" />
              </div>
            </div>
          </div>
          <div class="one-third"></div>
        </div>
      </div>
    </div>
    <div *ngIf="showOptions">
      <div class="flex-row-container">
        <h2 class="header-label rem-1-5" i18n="@@hotelSearchOptions">Options</h2>
      </div>
      <div class="half-container">
        <div class="half-block">
          <label
            class="header-label"
            id="hotelname"
            for="hotel-input-hotelname"
            i18n="@@hotelSearchHotelName"
            >Hotel Name</label
          >
          <i class="ico-home input-box-icon" aria-hidden="true"></i>
          <input
            autocomplete="off"
            class="input-box"
            i18n-placeholder
            id="hotel-input-hotelname"
            placeholder="Enter Hotel Name"
            [(ngModel)]="searchParams.hotel_name"
            aria-labelledby="hotelname" />
        </div>
        <div class="half-block">
          <label
            class="header-label"
            id="hotelchain"
            for="hotel-input-hotelchain"
            i18n="@@hotelSearchHotelchain"
            >Hotel Chain</label
          >
          <i class="ico-home combo-box-icon" aria-hidden="true"></i>
          <input
            autocomplete="off"
            class="combo-box"
            i18n-placeholder
            placeholder="Enter Hotel Chain"
            [(ngModel)]="searchParams.hotel_chain"
            [ngbTypeahead]="searchChains"
            [resultTemplate]="rtp"
            id="hotel-input-hotelchain"
            [inputFormatter]="chainFormatter"
            aria-labelledby="hotelchain"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
        </div>
      </div>
      <br/>
      <div class="half-container" *ngIf="crownSet">
        <div class="half-block">
          <mat-checkbox
          color="primary"
          class="light-primary"
          name="crownOnly"
          value="crownOnly"
          [(ngModel)]="searchParams.crownRatesOnly"
          aria-label="Crown Rates Only"
          >
          <span i18n="@@hotelCrownOnly">Crown Rates Only</span>
        </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <!-- Loading Spinner - show when fetching data from backend -->
  <!-- <div *ngIf='loadingOffices'>
    <i class="button_spinner button_spinner--large"></i>
    <h3 class='loading_message'> {{loadingChains ? "Loading Chains...": "Loading Offices..."}} </h3>
  </div> -->
</ng-container>
<app-hotel-loading-screen></app-hotel-loading-screen>

