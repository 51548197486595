<!-- Rail -->
<div class="cart_item" [class.duplicate-item]="duplicateBooking">
  <div class="cart_item_wrapper">
    <ng-container
      *ngIf="
        rail.searchQuery.journeyType === 'SingleJourney' ||
        rail.searchQuery.journeyType === 'OpenReturnJourney'
      ">
      <ng-container *ngIf="rail?.detail as detail">
        <div [class.duplicate-item-wrapper]="duplicateBooking">
          <div *ngIf="duplicateBooking" class="duplicate-item-date">
            <i class="ico-rail"></i>&nbsp;
            <ng-container *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime">
              <span i18n="@@basketPanelDepartureDateRail">Date:</span>
              <strong>{{ departureDateTime | sabsDate : 1 }}</strong>
              <strong *ngIf="!detail.isEU">
                {{ departureDateTime | date : 'HH:mm' }}
              </strong>
              <strong *ngIf="detail.isEU">
                {{ departureDateTime | sabsTimePipe : 'trainline_eu_time' }}
              </strong>
            </ng-container>
          </div>
          <div [class.duplicate-item-info]="duplicateBooking">
            <div class="cart_item_heading">
              <ng-container *ngIf="!duplicateBooking">
                <i class="ico-rail"></i>&nbsp;
              </ng-container>
              <span
                >{{ detail.outwardDetail.departureStationName }} <span i18n="@@to">to</span>
                {{ detail.outwardDetail.arrivalStationName }}</span
              >
              <button
                *ngIf="!duplicateBooking"
                class="cart_item_remove ico-trash"
                i18n-title
                attr.title="Remove {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                attr.aria-label="Remove {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} rail from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                (click)="remove.emit()"></button>
              <img
                *ngIf="!duplicateBooking"
                src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/basket_move.svg"
                class="move_basket_icon"
                ngbTooltip="Move to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                i18n-ngbTooltip
                role="button"
                attr.aria-label="Move {{ detail.outwardDetail.departureStationName }} to {{
                  detail.outwardDetail.arrivalStationName
                }} rail to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                (click)="openChangeModal.emit()"
                (keydown.enter)="openChangeModal.emit()"
                (keydown.space)="openChangeModal.emit()"
                tabindex="0" />
            </div>
            <ng-container *ngIf="detail?.outwardDetail?.supplierTickets[0] as supplierTicket">
              <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                <span i18n="@@basketPanelOperatorRail">Operator:</span>
                <strong>{{ getRailOperators(legs) }}</strong>
              </div>
              <div
                class="cart_item_rail_ticket_name"
                *ngIf="supplierTicket?.ticketDescription as ticketDescription"
                (click)="
                  openTicketCondition(
                    detail?.outbound,
                    detail?.termsAndCond?.outboundTerms,
                    detail.outwardDetail.departureStationName,
                    detail.outwardDetail.arrivalStationName
                  )
                ">
                <img
                  *ngIf="detail?.outbound?.isSplit"
                  class="split-ticket-logo"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                  alt="Split Tickets" />
                <strong
                  [ngClass]="detail?.outbound?.isSplit ? 'ticket-name-split' : 'ticket-name'"
                  >{{ ticketDescription }}</strong
                >
              </div>
              <div *ngIf="detail?.outbound?.isSplit && detail?.outbound?.fares?.length">
                <mat-accordion>
                  <mat-expansion-panel class="split">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span>
                          <img
                            class="split-ticket"
                            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                            alt="Split Tickets"
                        /></span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let fare of detail?.outbound?.fares">
                      <span class="split-fare-title">
                        {{ fare.quantity }} x {{ fare.name }}:
                        {{ fare.price | currency : fare.currency }}</span
                      >
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div
                class="cart_item_rail_permitted_stations"
                *ngIf="outwardPermittedOrigins?.length || outwardPermittedDestinations?.length">
                <span>
                  Travel also permitted
                  {{ outwardPermittedOrigins?.length ? 'from' : 'to' }} stations near
                  {{
                    outwardPermittedOrigins?.length
                      ? detail.outwardDetail.departureStationName
                      : detail.outwardDetail.arrivalStationName
                  }}{{
                    outwardPermittedDestinations?.length && outwardPermittedOrigins?.length
                      ? ' and to stations near ' + detail.outwardDetail.arrivalStationName
                      : ''
                  }}.
                  <u container="body" [ngbTooltip]="outwardPermittedStations"
                    >View stations</u
                  ></span
                >
              </div>
              <div *ngIf="openReturnValidity">
                <strong>{{ openReturnValidity }}</strong>
              </div>
              <div *ngIf="detail.outbound?.ticketExtras as ticketExtras">
                <div class="cart_item_rail_ticket_name">
                  <strong>Travelcard {{ ticketExtras[0].location | translate }}</strong>
                </div>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="
              !duplicateBooking && detail?.outwardDetail?.departureDateTime as departureDateTime
            "
            class="cart_item_rail_ticket_times">
            <span i18n="@@basketPanelDepartureDateRail">Date:</span>
            <strong>{{ departureDateTime | sabsDate : 2 }}         
               <span *ngIf="!detail.isEU">
                {{ departureDateTime | sabsTimePipe : 'timeString'}}
              </span>
            <span *ngIf="detail.isEU">
              {{ departureDateTime | sabsTimePipe: 'trainline_eu_time' }}
            </span></strong>
          </div>
        </div>
        <div *ngIf="!duplicateBooking" class="car_cart_item_to_point"></div>
        <div class="cart_item_price">
          <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostRail">Cost</strong>
          <strong>{{ rail.price | currency : rail.currency }}</strong>
          <em *ngIf="!duplicateBooking">
            <span i18n="@@basketPanelTotalPriceRail">&nbsp;Total Price</span>
            <span *ngIf="numberTravellersText">{{ numberTravellersText }}</span>
          </em>
        </div>
        <div class="cart_item_full_price" *ngIf="rail.detail.fullPrice > rail.price">
          <strong
            ><del>{{ rail.detail.fullPrice | currency : rail.currency }}</del></strong
          >
        </div>
        <div *ngIf="appliedDiscounts.length" class="applied_discounts">
          <app-rail-cards
            *ngIf="appliedDiscounts"
            [railcards]="appliedDiscounts"
            [basketPanel]="true"></app-rail-cards>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="rail.searchQuery.journeyType === 'ReturnJourney'">
      <ng-container *ngIf="rail?.detail as detail">
        <div>
          <div [class.duplicate-item-wrapper]="duplicateBooking">
            <div *ngIf="duplicateBooking && detail.outwardDetail" class="duplicate-item-date">
              <i class="ico-rail"></i>&nbsp;
              <ng-container *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime">
                <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                <strong>{{ departureDateTime | sabsDate : 2 }}         
                  <span *ngIf="!detail.isEU">{{
                 departureDateTime | sabsTimePipe : 'timeString'
               }}</span>
               <span *ngIf="detail.isEU">{{
                 departureDateTime | sabsTimePipe : 'trainline_eu_time'
               }}</span></strong>
              </ng-container>
            </div>
            <div [class.duplicate-item-info]="duplicateBooking">
              <div class="cart_item_heading" *ngIf="detail.outwardDetail as outwardDetail">
                <ng-container *ngIf="!duplicateBooking">
                  <i class="ico-rail"></i>&nbsp;
                </ng-container>
                <span
                  >{{ outwardDetail.departureStationName }} to
                  {{ outwardDetail.arrivalStationName }}</span
                >
                <button
                  *ngIf="!duplicateBooking"
                  class="cart_item_remove ico-trash"
                  ngbTooltip="Remove {{ outwardDetail.departureStationName }} to {{
                    outwardDetail.arrivalStationName
                  }} from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                  attr.aria-label="Remove {{ outwardDetail.departureStationName }} to {{
                    outwardDetail.arrivalStationName
                  }} rail from your {{displayItinerary ? 'itinerary' : 'basket'}}"
                  i18n-ngbTooltip
                  (click)="remove.emit()"></button>
                <img
                  *ngIf="!duplicateBooking"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/basket_move.svg"
                  class="move_basket_icon"
                  role="button"
                  ngbTooltip="Move to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                  i18n-ngbTooltip
                  attr.aria-label="Move {{ outwardDetail.departureStationName }} to {{
                    outwardDetail.arrivalStationName
                  }} rail to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                  ngbTooltip="Move item to different {{displayItinerary ? 'itinerary' : 'basket'}}"
                  (click)="openChangeModal.emit()"
                  (keydown.enter)="openChangeModal.emit()"
                  (keydown.space)="openChangeModal.emit()"
                  tabindex="0" />
              </div>
              <ng-container *ngIf="detail?.outwardDetail?.supplierTickets[0] as supplierTicket">
                <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                  <span i18n="@@basketPanelOperatorRail">Operator:</span>
                  <strong>{{ getRailOperators(legs) }}</strong>
                </div>
                <div
                  class="cart_item_rail_ticket_name"
                  *ngIf="supplierTicket?.ticketDescription as ticketDescription"
                  (click)="
                    openTicketCondition(
                      detail?.outbound,
                      detail?.termsAndCond?.outboundTerms,
                      detail.outwardDetail.departureStationName,
                      detail.outwardDetail.arrivalStationName
                    )
                  ">
                  <img
                    *ngIf="detail?.outbound?.isSplit"
                    class="split-ticket-logo"
                    src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                    alt="Split Tickets" />
                  <strong
                    [ngClass]="detail?.outbound?.isSplit ? 'ticket-name-split' : 'ticket-name'"
                    >{{ ticketDescription }}</strong
                  >
                </div>
                <div *ngIf="detail?.outbound?.isSplit && detail?.outbound?.fares?.length">
                  <mat-accordion>
                    <mat-expansion-panel class="split">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <span
                            ><img
                              class="split-ticket"
                              src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                              alt="Split Tickets"
                          /></span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div *ngFor="let fare of detail?.outbound?.fares">
                        <span class="split-fare-title">
                          {{ fare.quantity }} x {{ fare.name }}:
                          {{ fare.price | currency : fare.currency }}</span
                        >
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div
                  class="cart_item_rail_permitted_stations"
                  *ngIf="outwardPermittedOrigins?.length || outwardPermittedDestinations?.length">
                  <span>
                    Travel also permitted
                    {{ outwardPermittedOrigins?.length ? 'from' : 'to' }} stations near
                    {{
                      outwardPermittedOrigins?.length
                        ? detail.outwardDetail.departureStationName
                        : detail.outwardDetail.arrivalStationName
                    }}{{
                      outwardPermittedDestinations?.length && outwardPermittedOrigins?.length
                        ? ' and to stations near ' + detail.outwardDetail.arrivalStationName
                        : ''
                    }}.
                    <u container="body" [ngbTooltip]="outwardPermittedStations"
                      >View stations</u
                    ></span
                  >
                </div>
                <div *ngIf="detail.outbound?.ticketExtras as ticketExtras">
                  <div class="cart_item_rail_ticket_name">
                    <strong i18n="@@itineraryRailTravelcard">Travelcard: </strong>
                    <ng-container *ngFor="let item of ticketExtras; let last = last">
                      {{ item.location | translate }} ({{
                        item.date | date : 'EEEE dd MMMM YYYY'
                      }})<span *ngIf="!last">,</span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <div
                *ngIf="detail?.outwardDetail?.departureDateTime as departureDateTime"
                class="cart_item_rail_ticket_times">
                <ng-container *ngIf="!duplicateBooking">
                  <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                  <strong>{{ departureDateTime | sabsDate : 2  }}
                    <span *ngIf="!detail.isEU">
                      {{ departureDateTime | sabsTimePipe : 'timeString'}}
                    </span>
                    <span *ngIf="detail.isEU">
                      {{ departureDateTime | sabsTimePipe : 'trainline_eu_time'}}
                    </span>
                  </strong>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="rail_return_container"></div>
          <div [class.duplicate-item-wrapper]="duplicateBooking">
            <div *ngIf="duplicateBooking && detail.inwardDetail" class="duplicate-item-date">
              <i class="ico-rail"></i>&nbsp;
              <ng-container *ngIf="detail?.inwardDetail?.departureDateTime as departureDateTime">
                <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                <strong>{{ departureDateTime | sabsDate : 2 }}         
                  <span *ngIf="!detail.isEU">{{
                 departureDateTime | sabsTimePipe : 'timeString'
               }}</span>
               <span *ngIf="detail.isEU">{{
                 departureDateTime | sabsTimePipe : 'trainline_eu_time'
               }}</span></strong>
              </ng-container>
            </div>
            <div [class.duplicate-item-info]="duplicateBooking">
              <div class="cart_item_heading" *ngIf="detail.inwardDetail as inwardDetail">
                <ng-container *ngIf="!duplicateBooking">
                  <i class="ico-rail"></i>&nbsp;
                </ng-container>

                <span
                  >{{ inwardDetail.departureStationName }} to
                  {{ inwardDetail.arrivalStationName }}</span
                >
              </div>
              <ng-container *ngIf="detail?.inwardDetail?.supplierTickets[0] as supplierTicket">
                <div class="cart_item_operator" *ngIf="supplierTicket?.legs as legs">
                  <span i18n="@@basketPanelOperatorRail">Operator:</span>
                  <strong>{{ getRailOperators(legs) }}</strong>
                </div>
                <div
                  class="cart_item_rail_ticket_name"
                  *ngIf="supplierTicket?.ticketDescription as ticketDescription"
                  (click)="
                    openTicketCondition(
                      detail?.return,
                      detail?.termsAndCond?.inboundTerms,
                      detail.inwardDetail.departureStationName,
                      detail.inwardDetail.arrivalStationName
                    )
                  ">
                  <img
                    *ngIf="detail?.return?.isSplit"
                    class="split-ticket-logo"
                    src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket-logo.png"
                    alt="Split Tickets" />
                  <strong
                    [ngClass]="detail?.return?.isSplit ? 'ticket-name-split' : 'ticket-name'"
                    >{{ ticketDescription }}</strong
                  >
                </div>
                <div *ngIf="detail?.return?.isSplit && detail?.return?.fares?.length">
                  <mat-accordion>
                    <mat-expansion-panel class="split">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <span
                            ><img
                              class="split-ticket"
                              src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                              alt="Split Tickets"
                          /></span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div *ngFor="let fare of detail?.return?.fares">
                        <span class="split-fare-title">
                          {{ fare.quantity }} x {{ fare.name }}:
                          {{
                            fare.price ? (fare.price | currency : fare.currency) : 'See Outbound'
                          }}</span
                        >
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div
                  class="cart_item_rail_permitted_stations"
                  *ngIf="inwardPermittedOrigins?.length || inwardPermittedDestinations?.length">
                  <span>
                    Travel also permitted
                    {{ inwardPermittedOrigins?.length ? 'from' : 'to' }} stations near
                    {{
                      inwardPermittedOrigins?.length
                        ? detail.inwardDetail.departureStationName
                        : detail.inwardDetail.arrivalStationName
                    }}{{
                      inwardPermittedDestinations?.length && inwardPermittedOrigins?.length
                        ? ' and to stations near ' + detail.inwardDetail.arrivalStationName
                        : ''
                    }}.
                    <u container="body" [ngbTooltip]="inwardPermittedStations"
                      >View stations</u
                    ></span
                  >
                </div>
              </ng-container>
              <div
                *ngIf="detail?.inwardDetail?.departureDateTime as departureDateTime"
                class="cart_item_rail_ticket_times">             
                <ng-container *ngIf="!duplicateBooking">
                  <span i18n="@@basketPanelDepartureDateRail">Date:</span>
                  <strong>{{ departureDateTime | sabsDate : 2  }}
                    <span *ngIf="!detail.isEU">
                      {{ departureDateTime | sabsTimePipe : 'timeString'}}
                    </span>
                    <span *ngIf="detail.isEU">
                      {{ departureDateTime | sabsTimePipe : 'trainline_eu_time' }}
                    </span>
                  </strong>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!duplicateBooking" class="car_cart_item_to_point"></div>
        <div class="cart_item_price">
          <strong *ngIf="duplicateBooking" i18n="@@basketPanelCostRail">Cost</strong>
          <strong>{{ rail.price | currency : rail.currency }}</strong>
          <em *ngIf="!duplicateBooking">
            <span i18n="@@basketPanelTotalPriceRail">&nbsp;Total Price</span>
            <span *ngIf="numberTravellersText">{{ numberTravellersText }}</span>
          </em>
        </div>
        <div class="cart_item_full_price" *ngIf="rail.detail.fullPrice > rail.price">
          <strong
            ><del>{{ rail.detail.fullPrice | currency : rail.currency }}</del></strong
          >
        </div>
        <div *ngIf="appliedDiscounts.length" class="applied_discounts">
          <app-rail-cards
            *ngIf="appliedDiscounts"
            [railcards]="appliedDiscounts"
            [basketPanel]="true"></app-rail-cards>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #inwardPermittedStations>
      <div class="permitted_stations">
        <div *ngIf="inwardPermittedOrigins?.length"><strong>You can travel from:</strong></div>
        <ng-container *ngFor="let item of inwardPermittedOrigins">
          <div>{{ item }}</div>
        </ng-container>
        <div *ngIf="inwardPermittedDestinations?.length"><strong>You can travel to:</strong></div>
        <ng-container *ngFor="let item of inwardPermittedDestinations">
          <div>{{ item }}</div>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #outwardPermittedStations>
      <div class="permitted_stations">
        <div class="" *ngIf="outwardPermittedOrigins?.length">
          <strong>You can travel from:</strong>
        </div>
        <ng-container *ngFor="let item of outwardPermittedOrigins">
          <div>{{ item }}</div>
        </ng-container>
        <div *ngIf="outwardPermittedDestinations?.length"><strong> You can travel to:</strong></div>
        <ng-container *ngFor="let item of outwardPermittedDestinations">
          <div>{{ item }}</div>
        </ng-container>
      </div>
    </ng-template>

    <!-- TODO: handle OpenReturnJourney case -->
    <!-- TODO: handle DualSingleJourney case -->
  </div>

  <app-rail-journey-details
    *ngIf="!duplicateBooking"
    [basketItem]="rail"></app-rail-journey-details>
</div>
