<section (mouseleave)="tt.close()"
         role="radio"
         aria-live="polite"
         (keydown.enter)="click($event)"
         (keydown.space)="click($event)"
         [attr.aria-checked]="selected"
         tabindex="0"
         (click)="click($event)">
  <div class="light-toggle-btn"
       [class.light-toggle-btn-disabled]="disabled"
       [class.selected]="selected"
       placement="bottom-left"
       ngbTooltip="{{tooltipText}}"
       attr.aria-label="{{tooltipText}}"
       triggers="manual"
       #tt="ngbTooltip"
       (mouseenter)="tt.open()">
    <i class="light-toggle-btn-icon ico {{iconClass}}"
       *ngIf="iconClass"
       aria-hidden="true"></i>
    <div class="light-toggle-btn-content">
      <ng-content></ng-content>
    </div>
  </div>
</section>