<div class="flightFilterHeader">
  <ng-container>
    <h2 class="sortbylabel" i18n="@@flightFiltersSortBy">Sort by</h2>
    <div class="sortOptionsDialog background-80 top-32">
      <ul class="sort_by_options background-80">
        <li
          *ngFor="let option of selectableFilters"
          (click)="setSortby(option.sortType, display)"
          (keydown.enter)="setSortby(option.sortType, display)"
          [ngClass]="{ active_option: sortType === option.sortType }"
          role="button"
          [attr.aria-label]="option.ariaLabel"
          id="flight-sort-{{ option.title | removeSpaces }}"
          [attr.aria-pressed]="sortBy === option.sortType"
          tabindex="0"
          (keydown.arrowdown)="moveArrowDown($event)"
          (keydown.esc)="displaySortOptions = false">
          <span>{{ option.title | translate }}</span>
          <i [ngClass]="option.icon" *ngIf="option.icon !== 'ico-gbp'"></i>
          <img
            src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/filter-coin-icon.svg"
            *ngIf="option.icon === 'ico-gbp'"
            class="coin-svg" />
        </li>
      </ul>
    </div>

    <!-- <button
    [attr.aria-expanded]="displaySortOptions"
    class="filter background-40 secondary-text-colour"
    (click)="displaySortOptions= !displaySortOptions"
    aria-label="sort"
  >
    <span i18n="@@flightSortSort" aria-hidden="true">{{
        sortType | translate
    }}</span>
  </button> -->
    <div class="flex-row-container">
      <h2 i18n="@@flightFiltersTitle">Filters</h2>
      <span
        class="filters-clear-top"
        tabindex="0"
        role="button"
        id="flight-filter-button-clear"
        aria-label="Clear filters"
        (click)="clear()"
        (keydown.enter)="clear()"
        (keydown.space)="clear()"
        i18n="@@fligthfilterClear"
        >Clear</span
      >
    </div>

    <div class="mainframe" id="filters">
      <div class="flex-column-container">
        <div class="privateFares" *ngIf="(ourFilters?.privateFares | json) !== '{}'">
          <h3>
            <b i18n="@@privateFares">Private Fares</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.privateFares"
            [alphabetical]="true"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <div class="vias" *ngIf="searchParams.via">
          <h3>
            <b i18n="@@filterFlightsVia">Via</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.vias"
            [alphabetical]="true"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <div class="pricerange" *ngIf="!searchParams.bySchedule">
          <h3 id="price_range">
            <b i18n="@@filterFlightsPriceRange">Price Range</b>
          </h3>
          <div class="custom-slider">
            <ngx-slider
              aria-labelledby="price_range"
              id="flight-filter-price-range"
              [options]="priceOptions"
              (valueChange)="applyFilter(ourFilters)"
              (highValueChange)="applyFilter(ourFilters)"
              [(value)]="ourFilters.price.min"
              [(highValue)]="ourFilters.price.max">
            </ngx-slider>
          </div>
        </div>

        <div class="stops">
          <h3>
            <b i18n="@@filterFlightsStops">Stops</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.stops"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <div class="travelPolicy">
          <h3>
            <b i18n="@@travelPolicy">Travel Policy</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.travelPolicy"
            [alphabetical]="true"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <div class="operators">
          <h3>
            <b
              i18n="@@filterFlightsOutboundFlightNumber"
              id="outbound_flightnum_input_label"
              *ngIf="
                searchParams.chosenSearchType === FlightSearchType.return &&
                !searchParams.bySchedule
              "
              >Outbound Flight Number</b
            >
            <b
              i18n="@@filterFlightsFlightNumber"
              id="outbound_flightnum_input_label"
              *ngIf="
                searchParams.chosenSearchType !== FlightSearchType.return || searchParams.bySchedule
              "
              >Flight Number</b
            >
          </h3>
          <input
            class="input-box input-box--border input-box--no-iconed"
            [(ngModel)]="ourFilters.flightNumber"
            aria-labelledby="flightnum_input_label"
            id="flight-filters-input-flightnumber"
            placeholder="Enter flight number..."
            (ngModelChange)="applyFilter(ourFilters)"
            type="text" />
        </div>
        <div
          class="operators"
          *ngIf="
            searchParams.chosenSearchType === FlightSearchType.return && !searchParams.bySchedule
          ">
          <h3>
            <b i18n="@@filterFlightsInboundFlightNumber" id="inbound_flightnum_input_label"
              >Inbound Flight Number</b
            >
          </h3>
          <input
            class="input-box input-box--border input-box--no-iconed"
            [(ngModel)]="ourFilters.inboundFlightNumber"
            aria-labelledby="flightnum_input_label"
            id="flight-filters-input-flightnumber"
            placeholder="Enter flight number..."
            (ngModelChange)="applyFilter(ourFilters)"
            type="text" />
        </div>
        <div class="operators">
          <h3>
            <b i18n="@@filterFlightsAirlines">Airlines</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.airlines"
            [alphabetical]="true"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <h2 i18n="@@flightFiltersOutbound">Outbound</h2>
        <div class="deprange">
          <h3 id="departure_time_range">
            <b i18n="@@filterFlightsDepartureTime">Departure Time</b>
          </h3>
          <div class="custom-slider">
            <ngx-slider
              aria-labelledby="departure_time_range"
              [options]="depTimeOptions"
              id="flight-filter-dep-time"
              (valueChange)="applyFilter(ourFilters)"
              (highValueChange)="applyFilter(ourFilters)"
              [(value)]="ourFilters.departureTime.min"
              [(highValue)]="ourFilters.departureTime.max"></ngx-slider>
          </div>
        </div>
        <div class="arrrange">
          <h3 id="arrival_time_range">
            <b i18n="@@filterFlightsArrivalTime">Arrival Time</b>
          </h3>
          <div class="custom-slider">
            <ngx-slider
              aria-labelledby="departure_time_range"
              [options]="arrTimeOptions"
              (valueChange)="applyFilter(ourFilters)"
              (highValueChange)="applyFilter(ourFilters)"
              id="flight-filter-arrival-time"
              [(value)]="ourFilters.arrivalTime.min"
              [(highValue)]="ourFilters.arrivalTime.max"></ngx-slider>
          </div>
        </div>
        <div class="depPorts">
          <h3>
            <b i18n="@@filterFlightsDepartFrom">Depart from</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.depatureAirport"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <div class="arrPorts">
          <h3>
            <b i18n="@@filterFlightsArriveAt">Arrive at</b>
          </h3>
          <app-select-filter
            [items]="ourFilters?.arrivalAirport"
            (change)="applyFilter(ourFilters)"></app-select-filter>
        </div>
        <ng-container
          *ngIf="
            searchParams.chosenSearchType === FlightSearchType.return && !searchParams.bySchedule
          ">
          <h2 i18n="@@flightFiltersInbound">Inbound</h2>
          <div class="deprange">
            <h3 id="departure_time_range">
              <b i18n="@@filterFlightsDepartureTime">Departure Time</b>
            </h3>
            <div class="custom-slider">
              <ngx-slider
                aria-labelledby="departure_time_range"
                [options]="depTimeOptions"
                (valueChange)="applyFilter(ourFilters)"
                id="flight-filter-dep-time2"
                (highValueChange)="applyFilter(ourFilters)"
                [(value)]="ourFilters.returnDepartureTime.min"
                [(highValue)]="ourFilters.returnDepartureTime.max"></ngx-slider>
            </div>
          </div>
          <div class="arrrange">
            <h3 id="arrival_time_range">
              <b i18n="@@filterFlightsArrivalTime">Arrival Time</b>
            </h3>
            <div class="custom-slider">
              <ngx-slider
                aria-labelledby="departure_time_range"
                [options]="arrTimeOptions"
                (valueChange)="applyFilter(ourFilters)"
                id="flight-filter-arr-time2"
                (highValueChange)="applyFilter(ourFilters)"
                [(value)]="ourFilters.returnArrivalTime.min"
                [(highValue)]="ourFilters.returnArrivalTime.max"></ngx-slider>
            </div>
          </div>
          <div class="depPorts">
            <h3>
              <b i18n="@@filterFlightsDepartFrom">Depart from</b>
            </h3>
            <app-select-filter
              [items]="ourFilters?.depatureReturnAirport"
              [inbound]="true"
              (change)="applyFilter(ourFilters)"></app-select-filter>
          </div>
          <div class="arrPorts">
            <h3>
              <b i18n="@@filterFlightsArriveAt">Arrive at</b>
            </h3>
            <app-select-filter
              [items]="ourFilters?.arrivalReturnAirport"
              [inbound]="true"
              (change)="applyFilter(ourFilters)"></app-select-filter>
          </div>
        </ng-container>
        <!-- <div class="greenChoice">
            <h3>
                <b i18n="@@filterFlightsGreenerTitle">Greener Choice</b>
            </h3>
            <mat-checkbox color="primary"
                          class="light-primary"
                          name="onlyGreener"
                          value="onlyGreener"
                          [checked]="filter_options.onlyGreener"
                          i18n="@@flightFilterGreenerChoice"
                          (change)="applyFilter($event, filter_options.onlyGreener, 'onlyGreener')"
                          (keydown.enter)="applyFilter($event, filter_options.onlyGreener, 'onlyGreener')">
                Show Only CTM Greener Choice
            </mat-checkbox>
        </div> -->
        <button
          class="small-button"
          (click)="clear()"
          i18n="@@fligthfilterClear"
          id="flight-filters-clear"
          aria-label="Clear filters">
          Clear
        </button>
      </div>
    </div>
  </ng-container>
</div>
