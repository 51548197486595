<table aria-label="train times"
       i18n-aria-label
       class="data train_journey_table sticky-table">
    <tr>
        <th scope="row"
            i18n="@@railresultsheaderDepart">Depart</th>
        <td *ngFor="let journey of results; let i = index" [ngClass]="{'cancelled': journey.cancelled}"
            [attr.aria-label]="makeAriaLabel| memoize: journey">
            {{journey.departDateTime | sabsTimePipe}}
            <div *ngIf="datesAfterInitial[i]" class="plus_one" ngbTooltip="Departure date is next day.">
                +1
            </div>
        </td>
    </tr>
    <tr>
        <th scope="row"
            i18n="@@railresultsheaderArrive">Arrive</th>
        <td *ngFor="let journey of results; let i= index" [ngClass]="{'cancelled': journey.cancelled}">
            {{journey.arriveDateTime | sabsTimePipe}}
        </td>
    </tr>
    <tr>
        <th scope="row"
            i18n="@@railresultsheaderJourneyTime">Journey Time</th>
        <td *ngFor="let journey of results; let i= index" [ngClass]="{'cancelled': journey.cancelled}">
            {{journeyTime | memoize:journey.departDateTime:journey.arriveDateTime}}
        </td>
    </tr>
    <tr>
        <th scope="row"
            i18n="@@railresultsheaderChanges">Changes</th>
        <td *ngFor="let journey of results; let i= index" [ngClass]="{'cancelled': journey.cancelled}">
            {{journey.changes}}
        </td>
    </tr>

    <tr>
        <th scope="row"
            i18n="@@railresultsheaderCo2Kg">CO<sub>2</sub> Emissions(kg)</th>
        <td *ngFor="let journey of results; let i= index" [ngClass]="{'cancelled': journey.cancelled}">
            {{journey.co2PerPassenger || 'n/a'}}
        </td>
    </tr>

    <tr aria-hidden="true">
        <th scope="row"
            i18n="@@railresultsheaderOvertaken">Overtaken </th>
        <td *ngFor="let journey of results; let i = index">
            <span *ngIf="journey.overtaken"
                  class="overtaken"
                  i18n="@@railresultsheaderOvertaken">Overtaken </span>
            <span *ngIf="journey.cancelled"
                  class="cancelled"
                  i18n="@@railresultsheaderCancelled">Service Cancelled
            </span>
        </td>
    </tr>
    <tr>
        <th scope="row"></th>
        <td *ngFor="let journey of results; let i= index">
            <button class="details_link"
                    (click)="openDetailsModal(journey)">
                <span class="visuallyhidden"
                      i18n="@@railresultsheaderDetailsHidden">
                    Show details for outbound journey {{departStation | titlecase}} at {{journey.departDateTime |
                    sabsTimePipe}}
                </span>
                <span i18n="@@railresultsheaderShortDetailsHiddenButton" [ngClass]="{'cancelled': journey.cancelled}">Details</span>
            </button>
        </td>
    </tr>
    <tr>
        <th scope="row">
            <span class="visuallyhidden">Is this service a bus replacement service?</span>
        </th>
        <td *ngFor="let journey of results">
            <i class="ico-bus replacement_bus"
               *ngIf="checkForBus(journey)"></i>
        </td>
    </tr>
    <tr>
        <th scope="row">
            <span class="visuallyhidden">Is this service busy?</span>
        </th>
        <td *ngFor="let journey of results">
            <section (mouseleave)="tt.close()" class="container" *ngIf="busyTimes[journey.journeyHash]">
                <img src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/busy_train.png" 
                    alt="This service is predicted to be busy."
                    class="busy_train"
                    #tt="ngbTooltip"
                    (mouseenter)="tt.open()"
                    placement="bottom"
                    triggers="manual"
                    (focus)="tt.open()"
                    (blur)="tt.close()"
                    tabindex="0"
                    ngbTooltip="This service is predicted to be busy."
                    i18n-ngbTooltip>
            </section>
        </td>
    </tr>
</table>
