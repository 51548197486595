import { Component, ElementRef, ViewChildren, QueryList, EventEmitter } from '@angular/core';
import { ItemHotelsComponent } from '../item-hotels/item-hotels.component';
import { FilterHotelsComponent } from '../filter-hotels/filter-hotels.component';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LightningUserFavorurite } from '../../../../vendor/classes/user-favourite.enum';
import {
  HotelAvalibilityQuoteResult,
  HotelEnterpriseSearchInterface,
  HotelQuoteResult,
  LocationTypes,
  HotelViewController,
  HotelView,
  IHotelResultsConfiguration
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  Environment,
  HotelAvalibilityService,
  HotelItinerary,
  ServiceType,
  UserService,
  ModalOpenerService,
  HelperRoutines,
  HotelRatingIdentifiers,
  GetMultipleHotelRatingResponse,
  HotelRatingInfoAndTags,
  HotelGroupStaticTags
} from '@sabstravtech/obtservices/angular';
import { SortType } from '@sabstravtech/obtservices/base';
import { BaseComponent } from '../../../../vendor/components/base_components/base-componet';
import { DeviceDetector } from '../../../../vendor/services/device-detector.service';
import { HotelDetails } from '@sabstravtech/obtservices/base';
import {
  DistanceUnit,
  UnitType
} from '../../../../vendor/interfaces/hotel-search-form-configuration';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.scss']
})
export class HotelsComponent extends BaseComponent {
  public readonly moreInfo = 'hotels_moreinfo';

  @ViewChildren(ItemHotelsComponent, { read: ElementRef })
  public hotels: QueryList<ElementRef>;
  public arrayOfKeys: string[];
  public noOfRooms: number = 1;
  public show_errors: boolean;
  public showFilter: boolean = false;
  public showFilterOptions: boolean = false;
  public filteredSearchResults: HotelItinerary[] = [];
  public hotelViewController: HotelViewController;
  public showPreferredMessage: string = '';
  public showSortOptions: boolean = false;
  public activeLocation: LocationTypes;

  private delayValue: number = 200; // in ms
  private readyToClose: boolean = false;
  private scrollDebounce: any;
  private srchFromResults: boolean = false;
  private updatedHotelIds: string[] = [];
  public hiddenElements = [];
  public searchParams: HotelEnterpriseSearchInterface;
  public availabilityFilterState = false;
  public viewPortSize = new BehaviorSubject<number>(0);
  public currentResults = new BehaviorSubject<HotelDetails[]>([]);
  public secondTabResults = new BehaviorSubject<HotelDetails[]>([]);
  public loadingScreenInview = new BehaviorSubject<boolean>(false);
  public view: HotelView = HotelView.LIST;
  // Needs to delete
  secondTabSelected = false;

  public hotelAvalibility: {
    [key: number]: BehaviorSubject<HotelAvalibilityQuoteResult>;
  } = {};
  public hotelRatings: {
    [key: number]: BehaviorSubject<HotelRatingInfoAndTags>;
  } = {};
  public hotelGroupTags: {
    [key: number]: BehaviorSubject<HotelGroupStaticTags>;
  } = {};

  public maxValue: number = 0;
  public co2Number: number = 0;

  ServiceType: typeof ServiceType = ServiceType;
  LocationTypes: typeof LocationTypes = LocationTypes;
  userDistanceUnit: string;
  userLocalCurrency: string;
  sortType: SortType;
  HotelView = HotelView;
  swapSellHotelGroup: string[] = null;

  /**
   * Array of methods and titles for SortComponent
   *
   * @type {SortType[]}
   * @memberof HotelListComponent
   */
  sortTypes: SortType[] = [
    {
      methodRef: () => this.searchService.searches[ServiceType.Hotel].byPreferred(),
      title: this.translateService.instant('Preferred Hotels'),
      icon: 'ico-heart'
    },
    {
      methodRef: () => this.searchService.searches[ServiceType.Hotel].sortByDistance(),
      title: this.translateService.instant('Distance'),
      icon: 'ico-bed'
    },
    {
      methodRef: () => this.searchService.searches[ServiceType.Hotel].sortByName(),
      title: this.translateService.instant('Name'),
      icon: 'ico-hotel'
    },
    {
      methodRef: () => this.searchService.searches[ServiceType.Hotel].sortByPrice(),
      title: this.translateService.instant('Price'),
      icon: 'ico-gbp'
    }
  ];
  maxResultSize: number;
  loadingHotelRatings: boolean = false;

  constructor(
    public searchService: EnterpriseSearchService,
    public deviceDetector: DeviceDetector,
    private userService: UserService,
    // private filterHotelsService: FilterHotelsService,
    public hotelAvalibilityService: HotelAvalibilityService,
    title: Title,
    public translateService: TranslateService,
    private environment: Environment,
    private helpers: HelperRoutines,
    private modalOpenerService: ModalOpenerService
  ) {
    super(title, translateService);
    this.searchParams = searchService.searches[ServiceType.Hotel];
    this.searchParams.byPreferred();
  }

  ngOnInit(): void {
    const currencyObject = this.userService.getUserFavoriteObject<{
      currency: string;
      hotel: { enable: boolean; };
    }>(LightningUserFavorurite.PreferredCurrency);
    this.userLocalCurrency =
      currencyObject?.hotel?.enable && currencyObject?.currency ? currencyObject.currency : 'GBP';
    this.userDistanceUnit =
      this.userService.getUserFavoriteObject<{
        distanceUnit: DistanceUnit;
      }>(LightningUserFavorurite.HotelSearchFormConfiguration)?.distanceUnit?.unit ||
      UnitType.Miles;
    this.noOfRooms = this.searchParams.no_of_rooms;
    this.hotelAvalibility = {};
    const userFavoriteAvailability = this.userService.isUserFavoriteSet(
      LightningUserFavorurite.hotelFilterDefaultAvailability
    );
    if (userFavoriteAvailability === true) {
      this.applyWithUserFavoriteOnly();
    }
    this.hotelViewController = {
      showMap: false,
      ratesUpdated: new EventEmitter(false),
      filterChange: new EventEmitter<HotelQuoteResult[]>(),
      hotelChosen: false,
      filtered: false,
      filterArray: {},
      showFilterOptions: false,
      selectedVals: [],
      nr: 0,
      hotelCodeChosen: null,
      single: false,
      closeFilterOptions: () => {
        this.closeFilterOptions();
      }
    };

    if (!this.srchFromResults) {
      this.transResultsPreShow();
    }

    this.subscribe(this.searchParams.selectItemMapView, (hotel: any) => {
      this.hotelViewController.hotelChosen = hotel;
      this.hotelViewController.hotelCodeChosen = hotel.id;
      this.hotelViewController.showMap = true;
      this.hotelViewController.single = true;
    });
    this.viewPortSize.next(this.environment.numberHotelToPreload);
    this.subscribe(
      combineLatest([this.searchParams.results, this.viewPortSize]),
      ([results, size]: [HotelDetails[], number]) => {
        this.co2Number =
          results[0]?.co2PerItem /
          this.helpers.daysBetween(this.searchParams.checkin_date, this.searchParams.checkout_date);
        this.co2Number = Number(this.co2Number.toFixed(2));
        this.currentResults.next(results.filter((hotel: HotelDetails) => !hotel.secondTab).slice(0, size));
        this.secondTabResults.next(results.filter((hotel: HotelDetails) => hotel.secondTab).slice(0, size));
        if (!this.secondTabResults.value.length) {
          this.secondTabSelected = false;
        }
        this.maxResultSize = !this.secondTabSelected ? this.currentResults.value.length : this.secondTabResults.value.length;
        if (!this.loadingHotelRatings) {
          this.getHotelRatings(results.slice(0, size));
        }
      }
    );

    this.filterResults();
    this.setTitle(this.translateService.instant('Hotel results - LightUk'));
    this.filtersSubscription();

    this.subscribe(
      combineLatest([this.loadingScreenInview, this.hotelAvalibilityService.fetching]),
      ([inview, fetching]) => {
        // console.log(`+++ Loading Screen In View: ${inview} | Fetching: ${fetching} +++`);
        if (inview && !fetching) {
          this.updateHotelResults(inview);
        }
      }
    );

    this.view =
      (this.userService
        .getUserFavoriteObject<IHotelResultsConfiguration>(
          LightningUserFavorurite.hotelResultsFormConfiguration
        )
        ?.hotelSearchView?.hotelSearchView.toLowerCase() as HotelView) || HotelView.LIST;

    this.setView(this.view);
  }

  updateHotelResults(inview: boolean) {
    if (this.loadingScreenInview.value !== inview) {
      // recursion prevention
      this.loadingScreenInview.next(inview);
    }

    if (
      inview &&
      !this.hotelAvalibilityService.fetching.value &&
      this.viewPortSize.value < this.maxResultSize
    ) {
      console.log('Setting viewport to ', this.viewPortSize.value + 5);
      this.viewPortSize.next(this.viewPortSize.value + 5);
    }
    this.searchParams.hotelRoomReceived.next(true);
  }

  applyWithUserFavoriteOnly() {
    const checked = true;
    const type = 'Availability';
    const filterType = 'extra';
    // this.filterHotelsService.applyFilter({ checked }, type, filterType);
  }

  getCodeByHolidayCode(holidayCode): string {
    return holidayCode.substring(0, holidayCode.indexOf(','));
  }

  scrollToElement(f): void {
    const x = document.getElementById(f);
    x.scrollIntoView();
  }

  scrollToChosen(): boolean {
    // if (this.hotelViewController.hotelCodeChosen) {
    //   this.scrollToElement(this.hotelViewController.hotelCodeChosen);
    //   return true;
    // } else {
    //   return false;
    // }

    return false;
  }

  toggleFilterOptions(): void {
    // this.hotelViewController.showFilterOptions = !this.hotelViewController.showFilterOptions;
    this.showSortOptions = false;
    setTimeout(() => {
      this.readyToClose = !this.readyToClose;
    });
    this.ariaToggleHideBackground();
  }

  closeFilterOptions(): void {
    if (this.readyToClose) {
      // this.hotelViewController.showFilterOptions = false;
      this.readyToClose = false;
    }
    this.ariaToggleHideBackground();
  }

  transResultsPreShow(): void {
    // if (!this.travelService.search_loading) {
    //   this.travelService.getSearchResults(TravelTypeEnum.HOTEL);
    // }
    // this.hotelViewController.nr = this.searchParams.searchResults.length;
  }

  closeValidationErrors(): void {
    this.show_errors = false;
  }

  getNumHotelsMsg(): void {
    // if (this.hotelViewController.hotelChosen !== false) {
    //   return this.translateService.instant('Selected hotel:');
    // }
    if (!this.searchParams.results?.value?.length && !this.searchParams.crownRatesOnly) {
      return this.translateService.instant('No Results');
    } else if (!this.searchParams.results?.value?.length && this.searchParams.crownRatesOnly) {
      return null;
    } else {
      const numberResults = this.availabilityFilterState
        ? this.hotelAvalibilityService.numberOfHotelsWithPrice()
        : this.searchParams?.results?.value?.length;
      return this.translateService.instant(`Showing Hotels`, { num: numberResults });
    }
  }

  changeFilter(value: string[]) {
    if (!value) {
      this.clearFilters();
      return;
    }
    this.swapSellHotelGroup = value;
  }

  filterResults(): void {
    this.searchParams.filterLighteningResults();
  }

  setAvailabilityFilterState(state: boolean): void {
    this.availabilityFilterState = state;
  }

  setView(view: HotelView, hotelChosen = false): void {
    this.view = view;
    if (view === HotelView.MAP) {
      this.hotelViewController.hotelChosen = hotelChosen;
      this.hotelViewController.showMap = true;
      this.hotelViewController.single = false;
      if (hotelChosen === false) {
        this.updateHotels(this.getAllHotels());
      } else {
        this.updateHotels(this.convertHotelsToIds([this.filteredSearchResults[+hotelChosen]]));
      }
    } else {
      this.hotelViewController.hotelChosen = false;
      this.hotelViewController.showMap = false;
      //   this.transResultsPreShow();
      //   this.updateVisibleHotels();
    }
    // this.filterResults();
  }

  getLoadingMessage(): string {
    if (
      this.searchParams.location_type_select === 'Airport' &&
      this.searchParams.location &&
      this.searchParams.location.name
    ) {
      return `${this.translateService.instant('Hotels near')} ${this.searchParams.location.name}`;
    } else if (this.searchParams.location_type_select === 'City') {
      if (this.searchParams.location && this.searchParams.location.name) {
        return `${this.translateService.instant('Hotels near')} ${this.searchParams.location.name}`;
        //@ts-ignore
      } else if (this.searchParams.postcode && this.searchParams.postcode.name) {
        //@ts-ignore
        return `${this.translateService.instant('Hotels near')} ${this.searchParams.postcode.name}`;
      } else {
        return `${this.translateService.instant('Hotels near your chosen')} ${this.searchParams.location_type_select
          }`;
      }
    } else {
      return `${this.translateService.instant('Hotels near your chosen')} ${this.searchParams.location_type_select
        }`;
    }
  }

  async updateVisibleHotels() {
    this.updateHotels(await this.getVisibleHotels());
  }

  private updateHotels(hotels: string[]): void {
    // if (!hotels || !hotels.length) {
    //   return;
    // }
    // this.updatedHotelIds.push(...hotels);
    // if (this.updateHotelsRequest) {
    //   this.unsubscribe(this.updateHotelsRequest);
    // }
    // this.updateHotelsRequest = this.subscribe(this.hotelService.updateHotelRates(hotels, this.searchParams.maxOcc), data => {
    //   this.updateHotelRate(data);
    //   this.hotelViewController.ratesUpdated.emit(true);
    // });
    // switch (this.sortOption) {
    //   case 'distance':
    //     this.sortByDistance();
    //     break;
    //   case 'name':
    //     this.sortByName();
    //     break;
    //   case 'price':
    //     this.sortByPrice();
    //     break;
    //   default:
    //     this.filterResults();
    // }
  }

  /**
   * Gets Visible and never updated hotels
   */
  private getVisibleHotels(): Promise<string[]> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          this.hotels
            .filter(hotel => {
              const bounding = hotel.nativeElement.getBoundingClientRect();
              const wasUpdated = this.updatedHotelIds.includes(hotel.nativeElement.id);
              return bounding.top >= 0 && bounding.top <= window.innerHeight && !wasUpdated;
            })
            .map(item => {
              return item.nativeElement.id;
            })
        );
      }, this.delayValue);
    });
  }

  private getAllHotels(): string[] {
    return this.hotels?.map(item => {
      return item.nativeElement.id;
    });
  }

  private convertHotelsToIds(hotels: HotelQuoteResult[]): string[] {
    return hotels.map(hotel => {
      return this.getCodeByHolidayCode(hotel.id);
    });
  }

  isPrefHotelsSelected(): boolean {
    // return this.filterHotelsService.getPreferredHotelsSelected();
    return false;
  }

  backToHotelModal() {
    // this.filterHotelsService.applySavedFilters();
    // this.filterHotelsService.setPreferredHotelsSelected(false);
    // const lastSelectedHotel = this.filterHotelsService.getLastSelectedHotel();
    this.filterResults();

    // const roomRateDialog = this.modalService
    //   .open(
    //     LightningModalTypes.RatesHotelComponent,
    //     {
    //       height: '500px',
    //       width: '550px'
    //     },
    //     {
    //       data: lastSelectedHotel
    //     }
    //   )
    //   .then(result => {
    //     if (result && result.length > 0) {
    //       for (let i = 0; i < result.length; i++) {
    //         this.addToBasket(result[i].rate, lastSelectedHotel.hotel);
    //       }
    //     } else {
    //       this.        this.filterResults();
    //     }
    //   });
  }

  /**
  @desc - add a room to the basket - place it in a ChosenRoom - param for the hotel
  **/
  addToBasket(room: any, hotel: HotelQuoteResult): void {
    // this.searchParams.addToBasket(
    //   room,
    //   hotel,
    //   this.userService.getUserHotelTransactionFee(),
    //   this.basketService,
    //   this.travelService.getSearchParams(TravelTypeEnum.HOTEL)
    // );
  }

  moveArrowDown(e): void {
    e.preventDefault(); // prevents scrolling
    (<HTMLElement>document.activeElement.nextElementSibling).focus();
  }

  moveArrowUp(e): void {
    e.preventDefault(); // prevents scrolling
    (<HTMLElement>document.activeElement.previousElementSibling).focus();
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('hotel-search-1')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }
  switchTriggerPref(option: string) {
    // if (option === 'trigger') {
    //   if (!this.backAndForth) {
    //     this.searchParams.chosen_chain = null;
    //     this.searchParams.hotel_name = null;
    //     this.searchFromResults();
    //   }
    //   this.filterHotelsService.applyHotelsChain(this.desiredHotelsArr);
    //   this.showPreferredMessage = 'preferred';
    // } else if (option === 'preferred') {
    //   this.backAndForth = true;
    //   const originalSearch = this.trigHotelChain
    //     ? this.trigHotelChain
    //     : this.trigHotelName.split(' ').map((x: string) => x[0].toUpperCase() + x.slice(1).toLowerCase()).join(' ');
    //   this.filterHotelsService.applyHotelsChain([originalSearch]);
    //   this.showPreferredMessage = 'trigger';
    // }
    // this.        this.filterResults();
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (
        activeButton.offsetTop + activeButton.clientHeight >
        typeAheadList.clientHeight + typeAheadList.scrollTop
      ) {
        typeAheadList.scrollTop =
          activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log("Couldn't find elements to scroll");
    }
  }

  ariaToggleHideBackground(): void {
    if (this.hotelViewController.showFilterOptions) {
      this.hiddenElements = [];
      const selection = document.querySelectorAll('*');
      const wrapper = document.getElementById('hotel-filters-wrapper');
      for (let x = 0; x < selection.length; x++) {
        if (
          selection[x] !== wrapper &&
          !wrapper.contains(selection[x]) &&
          !selection[x].contains(wrapper) &&
          (!selection[x].getAttribute('aria-hidden') ||
            selection[x].getAttribute('aria-hidden') === 'false')
        ) {
          selection[x].setAttribute('aria-hidden', 'true');
          this.hiddenElements.push(selection[x]);
        }
      }
    } else {
      for (let x = 0; x < this.hiddenElements.length; x++) {
        this.hiddenElements[x].setAttribute('aria-hidden', 'false');
      }
    }
  }

  clearFilters() {
    const clearButton = document.getElementById('hotel-filters-clear');
    if (clearButton) {
      clearButton.click();
    }
  }

  fetchPrices(inView: boolean, hotel: HotelQuoteResult) {
    this.searchService.search_objects[ServiceType.Hotel].chosen = true;
    if (inView && hotel?.id && !this.hotelAvalibility[hotel.id]) {
      this.hotelAvalibility[hotel.id] = this.hotelAvalibilityService.getAvailabilityForId(
        this.searchParams.makeHotelAvalilityObject(hotel),
        hotel
      );
      // console.log(this.hotelAvalibility[hotel.id]);
    }
  }

  onSortChange(sortType: SortType) {
    sortType.methodRef();
    this.sortType = sortType;
  }

  performSearch() {
    if (window.location.href.includes('wellbeing')) {
      localStorage.setItem('prevURL', window.location.href); // prevent going back to wellbeing which will no longer have correct results
    }
    this.clearFilters();
    this.hotelRatings = {};
    this.hotelAvalibility = {};
    this.searchService.determineHighestSearchPriority();

    this.searchService.startSearches();
  }

  //#region ======= Private Functions ========
  private filtersSubscription() {
    this.subscribe(this.searchService.searches[ServiceType.Hotel].filterChange, () => {
      this.onFilterChange();
    });
  }

  /**
   * Event handler for filter change detection
   *
   * @memberof HotelListComponent
   */
  private onFilterChange(currentPageFromDetails = null) {
    this.filterResults();
    // this.currentPage = currentPageFromDetails !== null ? currentPageFromDetails : 1;
    // this.onPageChange(this.currentPage);
  }

  public getRoomDetail(hotel: HotelDetails) {
    let hotelAvalibility: HotelAvalibilityQuoteResult =
      this.hotelAvalibilityService.getAvailabilityForId(
        this.searchParams.makeHotelAvalilityObject(hotel),
        hotel
      ).value;
    this.noOfRooms = hotelAvalibility?.rooms?.length;
    return hotelAvalibility;
  }

  selectedHotelOnTheMap(id: string) {
    return this.searchParams.results.value.find((hotel: HotelDetails) => hotel.id === id);
  }

  getCountryCode() {
    switch (this.searchParams?.location_type_select) {
      case LocationTypes.City:
      case LocationTypes.Shortlist:
        return this.searchParams.country?.cCode || '';
      case LocationTypes.Airport:
        return this.searchParams.location?.airport?.countryCode || '';
      case LocationTypes.Office:
        return this.searchParams.office?.countryCode || '';
      case LocationTypes.TrainStation:
        return 'GB';
      default:
        return '';
    }
  }

  getHotelRatings(results: HotelDetails[]): void {
    if (results && results.length) {
      const hotelsWithoutRatings = results.filter((hotel) => {
        return !this.hotelRatings[hotel.name.toLowerCase()];
      });
      if (hotelsWithoutRatings.length) {
        this.loadingHotelRatings = true;
        const ratingsArgs: HotelRatingIdentifiers[] = hotelsWithoutRatings.map((hotel) => {
          return {
            hotelGroupName: hotel.group,
            hotelName: hotel.name
          };
        });
        this.searchService.getMultipleHotelRatings(ratingsArgs).subscribe((newHotelRatings: GetMultipleHotelRatingResponse) => {
          newHotelRatings.hotels.forEach((hotel) => {
            if (hotel && hotel.hotelName && !this.hotelRatings[hotel.hotelName.toLowerCase()]) {
              this.hotelRatings[hotel.hotelName.toLowerCase()] = new BehaviorSubject(hotel);
            }
          });
          hotelsWithoutRatings.forEach((hotel) => { //Add blank ratings for any results without ratings so that we don't reload them
            if (hotel && hotel.name && !this.hotelRatings[hotel.name.toLowerCase()]) {
              this.hotelRatings[hotel.name.toLowerCase()] = new BehaviorSubject({});
            }
          });
          newHotelRatings.hotelGroups.forEach((hotelGroup) => {
            hotelGroup.matchAgainstGroupName.forEach((hotelGroupName) => {
              if (hotelGroupName && !this.hotelGroupTags[hotelGroupName.toLowerCase()]) {
                this.hotelGroupTags[hotelGroupName.toLowerCase()] = new BehaviorSubject(hotelGroup);
              }
            });
          });
          this.loadingHotelRatings = false;
        });
      }
    }
  }
}

