import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WithSubscriptionComponent, User } from '@sabstravtech/obtservices/angular';
import { DifferentInvokdedUserReasons } from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
@Component({
    selector: 'app-different-invoked-user',
    templateUrl: './different-invoked-user.component.html'
})
export class DifferentInvokdedUserComponent extends WithSubscriptionComponent {
    //Inputs provided by caller.
    basketBobo: User = null;
    currentBobo: User = null;
    displayItinerary: boolean = false;

    // Other fields
    DifferentInvokdedUserReasons = DifferentInvokdedUserReasons;

    constructor(public activeModal: NgbActiveModal) {
        super();
    }
}

LightningModalTypes.DifferentInvokdedUserComponent.component = DifferentInvokdedUserComponent;
