<ngb-carousel [pauseOnHover]="true" [interval]="2000" *ngIf="results && results.data?.upa?.length && results.data?.upa?.length > 1">
    <ng-container *ngFor="let upa of results?.data?.upa">
        <ng-template ngbSlide *ngIf="upa.photos?.length">
            <div class="upa_image">
                <img [src]="upa.photos[0].largeUrl" alt=""/>
            </div>
            <div class="upa_header">
                {{upa.headline}}
            </div>
            <div class="upa_desc">
                {{upa.description}}
            </div>
        </ng-template>
    </ng-container>
</ngb-carousel>
<ng-container *ngIf="results && results.data?.upa?.length && results.data?.upa?.length === 1 && results.data?.upa[0].photos.length">
    <div class="upa_image">
        <img [src]="results.data?.upa[0].photos[0].largeUrl" />
    </div>
    <div class="upa_header">
        {{results.data?.upa[0].headline}}
    </div>
    <div class="upa_desc single_upa_desc">
        {{results.data?.upa[0].description}}
    </div>
</ng-container>
<div *ngIf="!results || !results.data?.upa?.length" class="error-message" i18n="@@modalRoutehappyUPAsUnavailable">Product info unavailable for this flight.</div>