import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { EnterpriseSearchService, UserService } from '@sabstravtech/obtservices/angular';
import { TravellerSearchTypes, Traveller } from '@sabstravtech/obtservices/base';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-share-basket-modal',
  templateUrl: './share-basket-modal.component.html',
  styleUrls: ['./share-basket-modal.component.scss']
})
export class ShareBasketModalComponent implements OnInit {

  shareUsers = [
    {
      user: null
    }
  ];

  displayItinerary = false;

  constructor(
    public activeModal: NgbActiveModal,
    private searchService: EnterpriseSearchService,
    private userService: UserService
  ) { }

  userFormatter = (x: Traveller) => x.email;

  ngOnInit(): void {
  }

  addAnotherEmail(): void {
    this.shareUsers.push({user: null});
  }

  removeEmail(index: number): void {
    this.shareUsers.splice(index, 1);
  }

  submit(): void {
    console.log(this.shareUsers);
    this.activeModal.close(this.shareUsers.map(user => user.user.id));
  }

  valid(): boolean {
    for (let i = 0; i < this.shareUsers.length; i++) {
      if (!this.shareUsers[i].user?.id) {
        return false;
      }
    }

    return true;
  }

  searchTravellers = (text$: Observable<string>) => {
    return text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string): Observable<Traveller[]> => {
            if (term && term.length > 2) {
                return this.searchService.searchTravellers(term, TravellerSearchTypes.email, this.userService.getfullUserDetails().allDetails.value.company.id);
            } else {
                return of([]);
            }
        })
    );
  };

}
LightningModalTypes.ShareBasketModalComponent.component = ShareBasketModalComponent;