import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';

import {
  ModalCardErrorComponent,
  ModalComponent,
  ModalConfirmCardComponent,
  ModalConfirmComponent,
  ModalCustomErrorComponent,
  ModalErrorComponent,
  ModalSuccessComponent,
  ModalLanguageSaveComponent,
  ModalScreenreaderBasketComponent,
  ModalSearchErrorComponent,
  ModalSupportEmailComponent,
  ModalTravellerErrorComponent,
  ModalUserErrorComponent,
  ModalSearchTimeoutComponent,
  ModalLiveRailLegsComponent,
  AutoLoggingOutComponent,
  ModalDeleteSavedAddresses,
  ModalNetworkErrorComponent,
  ModalHotelReviewsComponent,
  ModalCarDetailsComponent
} from './modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StartupModule } from '../startup/startup.module';
import { ModalRailJourneyDetailsComponent } from './modal-rail-journey-details/modal-rail-journey-details.component';
import { ModalRailJourneyTicketTermsComponent } from './modal-rail-journey-ticket-terms/modal-rail-journey-ticket-terms.component';
import { RiskAlertDetailModalComponent } from './risk-alert-detail-modal/risk-alert-detail-modal.component';
import { AlternativeRailModalComponent } from './alternative-rail-modal/alternative-rail-modal.component';
import { ResetPasswordModal } from './reset-password-modal/reset-password-modal.component';
import { ModalChangeBasketComponent } from './modal-change-basket/change-basket-modal.component';
import { ModalFareRulesComponent } from './fare-rules-modal/fare-rules-modal.component';
import { ModalIrlFareComponent } from './modal-irl-fare-rules/modal-irl-fare-rules.component';
import { FarerulesComponent } from './farerules-flights/farerules.component';
import { FareruleItemsComponent } from './farerules-flights/farerule-items/farerule-items.component';
import { ModalBrandedFareComponent } from '../modal-module/farerules-flights/modal-branded-fare/modal-branded-fare/modal-branded-fare.component';
import { RequiresReasonDialogComponent } from './requires-reason-dialog/requires-reason-dialog.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { DifferentInvokdedUserComponent } from './different-invoked-user/different-invoked-user.component';
import { DifferentTravellerPerBasketComponent } from './different-traveller-per-basket/different-traveller-per-basket.component';
import { NewVersionComponent } from './new-version/new-version.component';
import { UpdateSelectionDialogComponent } from './update-selection-dialog/update-selection-dialog.component';
import { QuoteModalComponent } from '../itinerary-module/modals/quote-modal/quote-modal.component';
import { ModalHotelPriceBreakdownComponent } from './modal-hotel-price-breakdown/modal-hotel-price-breakdown.component';
import { SendPdfByEmailComponent } from './send-pdf-by-email/send-pdf-by-email.component';
import {
  DuplicateBookingModalComponent,
  DuplicateBookingModalBodyComponent
} from './duplicate-booking-modal/index';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { BookingPriceChangesModalComponent } from './booking-price-changes-modal/booking-price-changes-modal.component';
import { ApproversModalComponent } from './approvers-modal/approvers-modal.component';
import { RequiresOverrideReasonDialogComponent } from './requires-override-reason-dialog/requires-override-reason-dialog.component';
import { TodBookingErrorModalComponent } from './tod-booking-error-modal/tod-booking-error-modal.component';
import { RailFareListComponent } from './modal-rail-ticket-terms/modal-rail-fare-list/rail-fare-list.component';
import { RailFareTermsComponent } from './modal-rail-ticket-terms/modal-rail-fare-terms/rail-fare-terms.component';
import { FlightRepriceModalComponent } from './flight-reprice-modal/flight-reprice-modal.component';
import { ShareBasketModalComponent } from './share-basket-modal/share-basket-modal.component';
import { ResendConfirmationComponent } from './resend-confirmation/resend-confirmation.component';
import { TaxiWarningModalComponent } from './taxi-warning-modal/taxi-warning-modal.component';
import { GmtHotelModalFareRulesComponent } from './gmt-hotel-fare-rules-modal/gmt-hotel-fare-rules-modal';
import { GmtCarModalFareRulesComponent } from './gmt-car-fare-rules-modal/gmt-car-fare-rules-modal';
import { GmtLoungeModalFareRulesComponent } from './gmt-lounge-fare-rules-modal/gmt-lounge-fare-rules-modal';
import { ResultsModule } from '../results-module/results.module';
import { GmtParkingModalFareRulesComponent } from './gmt-parking-fare-rules-modal/gmt-parking-fare-rules-modal';
import { GmtFastTrackModalFareRulesComponent } from './gmt-fast-track-fare-rules-modal/gmt-fast-track-fare-rules-modal';
import { GmtTaxiModalFareRulesComponent } from './gmt-taxi-fare-rules-modal/gmt-taxi-fare-rules-modal';
import { RoutehappyFlightModalComponent } from './routehappy-flight-modal/routehappy-flight-modal.component';
import { RoutehappyFlightModalUPAsComponent } from './routehappy-flight-modal/routehappy-flight-modal-upas/routehappy-flight-modal-upas.component';
import { RoutehappyFlightAmenitiesComponent } from './routehappy-flight-modal/routehappy-flight-modal-amenities/routehappy-flight-amenities.component';
import { HotelResultModalComponent } from './hotel-result-modal/hotel-result-modal.component';
import { GmtFlightDetailsModalComponent } from './gmt-flight-details-modal/gmt-flight-details-modal.component';
import { ModalDoorToDoorRailJourneyDetailsComponent } from './modal-door-to-door-rail-journey-details/modal-door-to-door-rail-journey-details';
import { ApprovalWarningModalComponent } from './approval-warning-modal/approval-warning-modal.component';
import { RailSeatAutoWarningModalComponent } from './rail-seat-auto-warning-modal/rail-seat-auto-warning-modal.component';
import { TaxiWheelchairModalComponent } from './taxi-wheelchair-modal/taxi-wheelchair-modal.component';

const PROVIDERS = [
  AlternativeRailModalComponent,
  FareruleItemsComponent,
  FarerulesComponent,
  ModalBrandedFareComponent,
  ModalCardErrorComponent,
  ModalChangeBasketComponent,
  ModalComponent,
  ModalConfirmCardComponent,
  ModalConfirmComponent,
  ModalCustomErrorComponent,
  ModalErrorComponent,
  ModalFareRulesComponent,
  ModalIrlFareComponent,
  ModalLanguageSaveComponent,
  ModalRailJourneyDetailsComponent,
  ModalDoorToDoorRailJourneyDetailsComponent,
  ModalRailJourneyTicketTermsComponent,
  ModalScreenreaderBasketComponent,
  ModalSearchErrorComponent,
  ModalSearchTimeoutComponent,
  ModalSuccessComponent,
  ModalSupportEmailComponent,
  ModalTravellerErrorComponent,
  ModalUserErrorComponent,
  RequiresReasonDialogComponent,
  RequiresOverrideReasonDialogComponent,
  ResetPasswordModal,
  RiskAlertDetailModalComponent,
  ModalLiveRailLegsComponent,
  InfoModalComponent,
  DifferentInvokdedUserComponent,
  DifferentTravellerPerBasketComponent,
  NewVersionComponent,
  UpdateSelectionDialogComponent,
  QuoteModalComponent,
  AutoLoggingOutComponent, ModalHotelPriceBreakdownComponent,
  SendPdfByEmailComponent,
  DuplicateBookingModalComponent,
  DuplicateBookingModalBodyComponent,
  BasicModalComponent,
  BookingPriceChangesModalComponent,
  ApproversModalComponent,
  TodBookingErrorModalComponent,
  ModalDeleteSavedAddresses,
  RailFareListComponent,
  RailFareTermsComponent,
  FlightRepriceModalComponent,
  ShareBasketModalComponent,
  ModalNetworkErrorComponent,
  ResendConfirmationComponent,
  TaxiWarningModalComponent,
  GmtHotelModalFareRulesComponent,
  GmtCarModalFareRulesComponent,
  GmtLoungeModalFareRulesComponent,
  GmtCarModalFareRulesComponent,
  GmtParkingModalFareRulesComponent,
  GmtFastTrackModalFareRulesComponent,
  GmtTaxiModalFareRulesComponent,
  RoutehappyFlightModalComponent,
  RoutehappyFlightModalUPAsComponent,
  RoutehappyFlightAmenitiesComponent,
  HotelResultModalComponent,
  ModalHotelReviewsComponent,
  GmtFlightDetailsModalComponent,
  ApprovalWarningModalComponent,
  ModalCarDetailsComponent,
  RailSeatAutoWarningModalComponent,
  TaxiWheelchairModalComponent
];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, MatCheckboxModule, FontAwesomeModule, StartupModule, ResultsModule, TabsModule.forRoot()],
  declarations: PROVIDERS,
  exports: PROVIDERS
})
export class ModalModule { }
