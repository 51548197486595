<div class="modal-header">
  <h3 class="modal-title"
      i18n="@@fastTrackPhotoTitle"
      id="modal-header">Fast Track photos</h3>
  <button type="button"
          class="close"
          aria-label="Fast Track photos"
          ngbAutoFocus
          (click)="activeModal.close()">
    <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
  </button>
</div>
<div class="grid">
  <ng-container *ngFor="let photo of photos; let index = index">
    <div (click)="selected[index] = true"
         class="grid-photo">
      <img [src]="removeSpaces(photo)"
           onerror="this.parentNode.style.display='none'" />
    </div>
    <div *ngIf="selected[index]"
         (click)="selected[index] = false"
         class="grid-photo-target">
      <img [src]="removeSpaces(photo)" />
    </div>
  </ng-container>
</div>