<ng-container *ngIf="{
  travellers: searchService.travellerInformation | async,
  favouriteTravellers: userService.favouriteTravellers | async
} as data">

  <div class='search_container'>
    <form autocomplete='off'>
      <!-- Traveller Select, this is always present for all forms, at the top of the form -->
      <div class='traveller' *ngIf="!showGuestTravellerForm">
        <app-invoke-user *ngIf='showInvokeUser'></app-invoke-user>
        <ng-container >
          <label for="name" class="header-label"
               id="addtravellers">
          <h2 i18n="@@addTravellersLabel"
              class="add-travellers-header">Add travellers for this itinerary</h2>
          <section (mouseleave)="tt.close()"
                   class="container"><i class='ico ico-info-circle'
               i18n-ngbTooltip
               ngbTooltip='Max of 8 Travellers'
               triggers="manual"
               #tt="ngbTooltip"
               (mouseenter)="tt.open()"
               (focus)="tt.open()"
               (blur)="tt.close()"
               tabindex="0"
               aria-label="Max of 8 Travellers"><span class="visuallyhidden">Max of 8 Travellers</span></i></section>
        </label>
        <div>
          <mat-radio-group [(ngModel)]="searchTravellersBy"
                           aria-label="Search user by"
                           [ngModelOptions]="{standalone: true}">
            <mat-radio-button value="email"
                              class="light-primary email-radio-btn"
                              color="primary"
                              i18n="@@searchTravellersByEmail">Email
            </mat-radio-button>
            <div class="surname_wrapper">
              <mat-radio-button value="name"
                                class="light-primary surname-checkbox"
                                color="primary"
                                i18n="@@searchTravellersBySurname">Surname
              </mat-radio-button>
              <section (mouseleave)="tt2.close()"
                       class="container"><i class='ico ico-info-circle surname-tooltip-icon'
                   i18n-ngbTooltip="@@surnameFilterTooltip"
                   triggers="manual"
                   (mouseenter)="tt2.open()"
                   (focus)="tt2.open()"
                   (blur)="tt2.close()"
                   #tt2="ngbTooltip"
                   tabindex="0"
                   i18n-aria-label="@@surnameFilterAriaLabel"
                   aria-label="You can filter the results further by adding the first name after the surname (surname, first name)"
                   ngbTooltip='You can filter the results further by adding the first name after the surname (surname, first name)'>
                  <span class="visuallyhidden"
                        i18n="@@surnameFilter">You can filter the results further by adding the first name after the
                    surname
                    (surname, first name)</span>
                </i>
              </section>
            </div>
            <mat-radio-button value="employeeNumber"
                              class="light-primary"
                              color="primary"
                              i18n="@@searchTravellersByEmployeeNo">Employee No.
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="travellers-form">
          <div class="travellers-select">
            <!-- <button class="sr-only sr-only-focusable small-button" 
                    aria-controls="traveller-input"
                    [attr.aria-expanded]="showTravelerInput"
                    (click)='showTravellerInput()'
                    [ngClass]="{'error-box': searchService.traveller_error}">{{showTravelerInput ? 'Hide' : 'Expand'}} traveller search</button> -->
            <div class='travellers_container'
              ngbTooltip='Please search for a user to book for using the field above'
              [disableTooltip]="!isAgentBooker"
                 [ngClass]="{'error-box': searchService.traveller_error, 'disabled_traveller_section': isAgentBooker && !userService.bobo}">
              <span *ngIf='data.travellers.length === 0'
                    class='traveller_placeholder'
                    (click)='showTravellerInput()'
                    i18n="@@addTravellersSearch">Search for a traveller</span>
              <ul *ngIf='data.travellers.length> 0'
                  class='selected_travellers'>
                <li *ngFor='let traveller of data.travellers; let i=index'>
                  <fa-icon
                    tabindex="0"
                    role="button"
                    [attr.aria-label]="'Add frequent traveller' + traveller.name"
                    *ngIf="!(traveller | checkFrequentTraveller: data.favouriteTravellers)"
                    (click)="addFrequentTravellers(traveller)"
                    [icon]="faUserRegular"
                    class="frequent-button"
                  ></fa-icon>
                  <fa-icon
                    tabindex="0"
                    role="button"
                    [attr.aria-label]="'Remove frequent traveller' + traveller.name"
                    *ngIf="traveller | checkFrequentTraveller: data.favouriteTravellers as frequentUser"
                    (click)="removeFrequentTraveller(frequentUser)"
                    [icon]="faUserSolid"
                    class="frequent-button"
                  ></fa-icon>
                  <span tabindex="0" class="travelle-wrapper">{{traveller.forename}} {{traveller.surname}}</span>
                  <i class='ico-cancel' *ngIf="i !== 0 || showRemoveUserButton"
                     tabindex="0"
                     role="button"
                     [attr.aria-label]="'Remove traveller ' + traveller.name"
                     id="removetraveller-button-{{i}}"
                     (click)='removeTraveller(traveller);$event.stopPropagation();'
                     (keydown.enter)="removeTraveller(traveller);$event.stopPropagation();"></i>
                </li>
                <span *ngIf="(!isSelfBooker) && (isMe || userService.useAgentCanBookFor()) && !currentNoOfTravellersGreaterOrEqualToMax"
                      tabindex="0"
                      role="button"
                      class="additional_passeger_message"
                      aria-controls="traveller-input"
                      [attr.aria-expanded]="showTravelerInput"
                      (click)='showTravellerInput()'
                      i18n="@@addTravellersAddAdditional"> Add additional passengers</span>
              </ul>
            </div>
            <div id="traveller-input"
                 class="traveller_input"
                 [hidden]="!showTravelerInput">
              <i class='ico-search'></i>
              <input autocomplete="off"
                     autofocus
                     type='text'
                     #input
                     id="name"
                     [disabled]='currentNoOfTravellersGreaterOrEqualToMax'
                     name='travellerInput'
                     [formControl]='travellerControl'
                     class='traveller_add_input'
                     tabindex="0" />
              <i class='traveller_spinner'
                 *ngIf='loadingTravellers'></i>
            </div>
            <ul class='traveller_options'
                [hidden]='travellerResults.length <= 0 || !showTravelerDropdown'>
              <li *ngFor='let result of travellerResults'
                  (click)='addToTravellerSelection(result)'
                  (keydown.enter)='addToTravellerSelection(result)'
                  tabindex="0"
                  role="button">
                {{result.surname}}, {{result.forename}} {{result.email}}</li>
            </ul>
            <ul class='traveller_options'
                [hidden]='loadingTravellers || travellerControl.value.length <3 || travellerResults.length> 0 || !showTravelerDropdown'>
              <li i18n="@@addTravellersNoResults">No Results found</li>
            </ul>

            <div class="erm-traveller-type-container triple-radio-buttons" *ngIf="data.travellers.length > 0 &&
              searchService?.search_objects[serviceType.Flight]?.chosen && ermCompanyType">
              <div class="erm-traveller-type-item" (click)="changedTravellerType(ermTravellerType.Joiner)" >
                <input type="checkbox" name="Joiner" [checked]="searchService.ermTravellerType === ermTravellerType.Joiner">
                <div rel="icon" type="image/x-icon" 
                [ngClass]="{'erm-traveller-type-img-ship': (ermCompanyType === CompanyType.CruiseLine || ermCompanyType === CompanyType.Marine),
                'erm-traveller-type-img-rig': (ermCompanyType === CompanyType.OilGas),
                'erm-traveller-type-img-windmill': (ermCompanyType === CompanyType.Mining || ermCompanyType === CompanyType.Wind)
               }"></div>
                <button
                [class]="searchService.ermTravellerType === ermTravellerType.Joiner ?
                'selected-traveller-type-button': 'unselected-traveller-type-button'"
                i18n="@@FlightSearchJoiner">Joiner</button>
              </div>
              <div class="erm-traveller-type-item" (click)="changedTravellerType(ermTravellerType.Leaver)" >
                <input type="checkbox" name="Leaver" [checked]="searchService.ermTravellerType === ermTravellerType.Leaver">
                <div rel="icon" type="image/x-icon" [ngClass]="{'erm-traveller-type-img-ship': (ermCompanyType === CompanyType.CruiseLine || ermCompanyType === CompanyType.Marine),
                'erm-traveller-type-img-rig': (ermCompanyType === CompanyType.OilGas),
                'erm-traveller-type-img-windmill': (ermCompanyType === CompanyType.Mining || ermCompanyType === CompanyType.Wind)
               }"></div>
                <button
                [class]="searchService.ermTravellerType === ermTravellerType.Leaver ?
                'selected-traveller-type-button': 'unselected-traveller-type-button'"
                i18n="@@FlightSearchLeaver">Leaver</button>
              </div>
            </div>

          </div>
          <div class="guest-button">
            <ng-container *ngIf="!isAgentBooker">
              <div class="row">
                <button type="button"
                        class="small-button small-button--left-margin small-button--mobile-margin"
                        ngbTooltip='Please search for a user to book for using the field above'
                        [disableTooltip]="!isAgentBooker"
                        (click)="addCurrentUser()"
                        id="add-myself-as-traveller-button"
                        [disabled]='!canAddSelf || (isAgentBooker && !userService.bobo)'
                        aria-label="Add Myself as Traveller">
                  <i *ngIf="!loadingCurrUser"
                      aria-hidden-="true"
                     class="ico ico-user add-me-icon"></i>
                  <span *ngIf="!loadingCurrUser"
                        i18n="@@addTravellersAddMe">Add Myself as Traveller</span>
                  <i class='button_spinner'
                     *ngIf='loadingCurrUser'></i>
                </button>
              </div>
              <span class="traveller_buttons_separator">&nbsp;</span>
            </ng-container>
            <div class="row" *ngIf="!isGetUsThere && (canAddGuest || isAgentBooker)">
              <button type="button"
                      ngbTooltip='Please search for a user to book for using the field above'
                      [disableTooltip]="!isAgentBooker"
                      class="small-button small-button--left-margin small-button--mobile-margin"
                      (click)='showGuestTravellerFormToggle()'
                      [disabled]="currentNoOfTravellersGreaterOrEqualToMax || (isAgentBooker && !userService.bobo)"
                      *ngIf='(userCanBookOthers && allowAddGuest) || (isSelfBooker && allowAddGuest)'
                      tabindex="0"
                      (keydown.enter)="showGuestTravellerFormToggle()"
                      aria-label="Add Guest">
                <i class="ico ico-new-user"
                    aria-hidden-="true"></i>
                <span i18n="@@addTravellersAddGuest">Add Guest</span>
              </button>
            </div>
          </div>
        </div>
        </ng-container>
        



        <div class="half-container flex-row-container">
          <div class="half-block">
            <mat-checkbox class="light-primary"
                          *ngIf="canUseTravellerProfile"
                          [(ngModel)]="userService.useTravellerProfile"
                          i18n="@@AddTravellerUseTravellerProfile"
                          (ngModelChange)="updateUseTravellerProfile()"
                          [ngModelOptions]="{standalone: true}">Use traveller's policy</mat-checkbox>
          </div>
        </div>
      </div>
      <div class='booking_panel_options'
           *ngIf='showGuestTravellerForm'>
        <button class='guest_back_arrow'
                (click)='showGuestTravellerFormToggle()'
                tabindex="0"
                aria-label="Back">
          <i class='ico ico-arrow-left' aria-hidden-="true"></i> <span i18n="@@addTravellersBack">Back</span></button>
        <div class="content-centre flex-column-container">
          <i class='ico ico-new-user header_icon'></i>
          <h2 class='guest_header_text text-center'
              i18n="@@addTravellersAddGuestHeading">Add a guest traveller to your
            trip</h2>
        </div>
      </div>
      <app-guest-traveller-form *ngIf='showGuestTravellerForm'
                                [selectedTravellers]="data.travellers"
                                (guest)="addGuest($event)"></app-guest-traveller-form>
    </form>
  </div>
</ng-container>
