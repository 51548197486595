<!--Announcements Display-->
<div class="announcement_dropdown_container" #announcementDropdown aria-modal="true" *ngIf="(announcements && (announcements | keyvalue).length) || (isTravelAdvice && travelAdvice)">

  <div *ngIf="isTravelAdvice">
    <div *ngIf="travelAdvice">
      <div class="announcement_labels">
        <h2 class="announcement_header first_header" i18n="@@announcementsTravelAdvice">Travel Advice</h2>
      </div>
      <div class="travel_advice advice_only">
        <div class="travel-advice-message">
          Due to the frequency of changes in FCO advice on international travel, we highly recommend
          visiting their website for the most up to date travel advice for
          {{ travelAdvice.country }}
        </div>
        <a
          class="more_info"
          target="_blank"
          href="{{ travelAdvice.link }}"
          i18n="@@announcementsMoreInfo"
          >More Info</a
        >
        <!--<div
          class="announcement_close"
          (click)="isTravelAdvice = false"
          (keydown.enter)="isTravelAdvice = false"
          attr.aria-label="Close {{ travelAdvice.country }} travel restriction announcement"
          tabindex="0"
          role="button">
          <i class="ico-cancel"></i>
        </div>-->
      </div>
    </div>
  </div>

  <div class="announcement" *ngFor="let annPage of announcements | keyValue; let keyIndex = index">
    <h2 class="announcement_header" [ngClass]="{ 'first_header': keyIndex === 0 && !(isTravelAdvice && travelAdvice) }">{{ annPage?.value[0]?.name }}</h2>
    <ul class="announcements_list">
      <ng-container *ngIf="annPage.value">
        <ng-container *ngFor="let ann of annPage.value; let i = index">
          <li>
            <span [innerHTML]="ann?.config?.message | safeHtml : 'html'"></span>
            <!--<div
              class="announcement_close"
              *ngIf="ann.clickToConfirm"
              (click)="deleteAnnouncement(ann.id)"
              (keydown.enter)="deleteAnnouncement(ann.id)"
              attr.aria-label="Close {{ ann?.config?.message }} announcement"
              tabindex="0"
              role="button">
              <i class="ico-cancel"></i>
            </div>-->
          </li>
          <div *ngIf="ann.userCanSupress && !ann.clickToConfirm">
            <mat-checkbox
              (click)="suppressAnnouncement(ann.id)"
              class="light-accent"
              color="primary"
              id="suppress_announcement"
              i18n="@@suppressAnnouncement"
              aria-label="I have read the message, do not display again">
              I have read the message, do not display again
            </mat-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
