import { Component, OnInit, ViewChild, Renderer2, Inject, HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Location, DOCUMENT } from '@angular/common';
import { LogonService, StorageService, Environment, ModalOpenerService, EnterpriseBasketService, EnterpriseSearchService, WithSubscriptionComponent, UserService } from '@sabstravtech/obtservices/angular';
import { VERSION } from '../../../../environments/version';
import { LanguageService } from '../../services/language.service';
import { Language } from '../../interfaces/language-types';
import { PageType } from '../../enum/page-type.enum';
import { fromEvent } from 'rxjs';
import { ThemeService } from '../../services/theme.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends WithSubscriptionComponent implements OnInit {
  title: string = 'Lightning UK Login';
  modalSubscription: Promise<any>;
  private previousURL = '';
  isRadius = false;
  isRefreshed = false;
  displayScrollButton = false;
  displayItinerary = false;

  constructor(
    private router: Router,
    private authService: LogonService,
    private storageService: StorageService,
    private cookieService: CookieService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private environmentObt: Environment,
    private _location: Location,
    public basketService: EnterpriseBasketService,
    public searchService: EnterpriseSearchService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private userService: UserService,
    private auth0: AuthService
  ) {
    super();
    this.translate.addLangs(['en', 'fr', 'de']);
    this.translate.setDefaultLang('en');

    const lang: Language = this.languageService.getLanguageFromRoute();
    this.translate.use(lang.abre);
    this.themeService.setTheme();
    if (!!authService.isLightningEnv) {
      this.injectUserCentricsScript();
    }
  }

  injectUserCentricsScript = () => {
    // Check if the script is already injected
    if (!this.document.getElementById("usercentrics-cmp")) {
      const script = this.document.createElement("script");
      script.id = "usercentrics-cmp";
      script.src = environment.UserCentricsUrl;
      script.setAttribute(
        "data-settings-id",
        environment.UserCentricsID // this is the ID????
      );
      script.async = true;
      this.document.body.appendChild(script);
      console.log(script);
      script.onload = () => {
        /* eslint-disable no-console */
        console.log("Usercentrics script loaded successfully");
      };
      script.onerror = () => {
        /* eslint-disable no-console */
        console.error("Failed to load the Usercentrics script");
      };
    }
  };


  @ViewChild('cookieLaw') public cookieLawEl;
  public dismiss(): void {
    if (this.cookieLawEl) {
      this.cookieLawEl.dismiss();
    }
  }

  async ngOnInit(): Promise<void> {
    const clicks = fromEvent(document, 'click');
    const keyDown = fromEvent(document, 'keydown');

    this.authService.obtVersion = VERSION;
    const haveAgencyUserCookie = this.cookieService.check('agencyLoggedIn');
    console.log('have', haveAgencyUserCookie);
    // console.log('+++ Checking Agency Cookie: ', haveAgencyUserCookie, ' +++');

    // if we have cookie and not session - go to sso page

    if (haveAgencyUserCookie && !this.storageService.getSessionItem('Agency_User')) {
      // console.log('+++ We have the agency user cookie set and are missing the Agency_User session storage +++');
      this.router.navigate(['/sso']);
    }

    keyDown.subscribe((event => {
      let keyboardEvent = event as KeyboardEvent;
      if (!this.document.body.classList.contains("user-is-tabbing") && keyboardEvent.key === "Tab") {
        this.renderer.addClass(this.document.body, 'user-is-tabbing');
      }
    }));

    clicks.subscribe((event) => {
      let pointerEvent = event as PointerEvent;
      if (this.document.body.classList.contains("user-is-tabbing") && pointerEvent.offsetX !== 0 && pointerEvent.offsetY !== 0) {
        // If user continues to click after tabbing we don't want the outline to appear anymore
        this.renderer.removeClass(this.document.body, 'user-is-tabbing');
      }

      /*THIS IS COVER FOR THIRD PARTY WCAG ISSUES, ngbTypeaheads automatically have the property 
      "aria-multiline" but this isn't a property they should have and it causes a WCAG violation so this is 
      just making sure the property is removed from them any time the user clicks onto a new page*/
      const multilines = document.querySelectorAll('[aria-multiline^="false"]');
      for (let x = 0; x < multilines.length; x++) {
        multilines[x].removeAttribute('aria-multiline');
      }
    });

    if (this.environmentObt.disable_console) {
      (<any>window).oldConsole = console.log;
      console.log = function () { };
      console.log('Test Unsuccessful!');
    } else {
      console.log('Console Enabled!');
    }

    this.subscribe(this.router.events, event => {
      if (event instanceof NavigationStart) {
        if (!this.previousURL || !this.previousURL.includes(event.url)) {
          this.previousURL = window.location.href;
          localStorage.setItem("prevURL", window.location.href);
        }
      }

      if (event instanceof NavigationEnd && !this.isRefreshed) {
        this.checkResultRefresh(event.url);
        this.isRefreshed = true;
      }
    });

    this.isRadius = (window.location.href.indexOf('radius') !== -1);

    this.subscribe(this.userService.fullUserDetails.allDetails, all => {
      this.displayItinerary = this.userService.isBasketReplacementUserFavouriteSet();
    })
  }

  back() {
    this._location.back();
  }

  get displayBack(): boolean {

    return window.location.href.includes('/results/') && !window.location.href.includes('wellbeing') && this.previousURL.includes('wellbeing');
  }

  checkResultRefresh(url: string) {
    if (url.includes(PageType.Results) || url.includes(PageType.Itinerary)) {
      this.searchService.search_objects = this.storageService.getSessionItem('search_params');
    }
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent() {
    this.displayScrollButton = window.scrollY > (window.innerHeight * 2);
  }

  getQueryParams(): { code: string, state: string; } {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');

    return { code, state };
  }
}

