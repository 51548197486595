import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../vendor/components/base_components/base-componet';
import { faUser, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import {
  UserService, User, UserApprovalRedirection, MutationEditUserArgs, ModalOpenerService, DocumentInput, UserDefaultManagementInfoValue,
  LogonService, ProfileConfig, ProfileConfigOption, UserApproverInput
} from '@sabstravtech/obtservices/angular';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LightningModalTypes } from '../vendor/classes/modal-types.enum';
import moment from 'moment';
import { Helpers } from '../vendor/classes/helpers';
import { Router } from '@angular/router';
import { LanguageService } from '../vendor/services/language.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss']
})
export class ProfileInformationComponent extends BaseComponent implements OnInit {
  faUser = faUser;
  faMapMarkerAlt = faMapMarkerAlt;
  faListAlt = faListAlt;
  tabSelected = 0;
  user: User;
  userMIs: UserDefaultManagementInfoValue[];
  approver: UserApprovalRedirection;
  profileForm: FormGroup;
  addressForm: FormGroup;
  loyaltiesForm: FormGroup;
  docsForm: FormGroup;
  travelPreferencesForm: FormGroup;
  miDefaultForm: FormGroup;
  userProfileConfig: ProfileConfig;
  profileConfigOption = ProfileConfigOption;
  approvalReassignmentForm: FormGroup;
  approvalForm: FormGroup;
  today: NgbDateStruct;
  constructor(
    title: Title,
    public translateService: TranslateService,
    public userService: UserService,
    private modalService: ModalOpenerService,
    private readonly router: Router,
    private authService: LogonService,
    private languageService: LanguageService
  ) {
    super(title, translateService);

    this.today = Helpers.dateToNgDate(new Date());

    this.profileForm = new FormGroup({
      title: new FormControl('', Validators.required),
      forename: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      dob: new FormControl(null),
      email: new FormControl('', [Validators.required, Validators.email]),
      additionalEmail: new FormArray([]),
      selectedLanguage: new FormControl('', Validators.required),
      phoneNumbers: new FormArray([]),
      emergencyContactName: new FormControl(''),
      emergencyContactPhone: new FormControl('')
    });

    this.addressForm = new FormGroup({
      addresses: new FormArray([])
    });

    this.docsForm = new FormGroup({
      documents: new FormArray([])
    });

    this.loyaltiesForm = new FormGroup({
      loyaltyCodes: new FormArray([])
    });

    this.travelPreferencesForm = new FormGroup({
      preferences: new FormArray([])
    });

    this.miDefaultForm = new FormGroup({
      userMiDefaultValues: new FormArray([])
    });

    this.approvalReassignmentForm = new FormGroup({
      approver: new FormControl(''),
      validFrom: new FormControl(this.today),
      validTo: new FormControl(null)
    });

    this.approvalForm = new FormGroup({
      userApprovers: new FormArray([])
    });

  }

  ngOnInit(): void {
    this.setTitle(this.translateService.instant('Profile Information - LightUK'));
    this.subscribe(combineLatest([this.userService.getUserMi(), this.userService.getUser()]), ([userMi, user]: [User, User]) => {
      if (user) {
        console.log(user);
        this.user = { ...userMi, ...user };
        //s    this.userMIs =     // getUserMIStack - might be this???

        this.userProfileConfig = this.user.profileConfig;
        this.setDefaultTab(this.user.profileConfig);
        this.userService.getUserDocumentLoyalty().subscribe((user: any) => {
          if (user) {
            this.user.documents = user.documents;
            this.user.loyaltyCodes = user.loyaltyCodes;
          }
        });
      }
      if (user.approver) {
        this.userService.getRedirectApprover().subscribe((approver: UserApprovalRedirection) => {
          this.approver = approver;
          this.approvalReassignmentForm.setValue({
            approver: approver?.redirectTo || '',
            validFrom: approver?.validFrom ? Helpers.dateToNgDate(new Date(approver.validFrom)) : this.today,
            validTo: approver?.validTo ? Helpers.dateToNgDate(new Date(approver.validTo)) : null,
          });
        });
      }
    }
    );

  }

  setDefaultTab(profileConfig: ProfileConfig): void {
    if (profileConfig?.profile === ProfileConfigOption.Editable || profileConfig?.profile === ProfileConfigOption.Visible) {
      this.tabSelected = 0;
    }
    if (profileConfig?.address === ProfileConfigOption.Editable || profileConfig?.address === ProfileConfigOption.Visible) {
      this.tabSelected = 1;
    }
    if (profileConfig?.miDefault === ProfileConfigOption.Editable || profileConfig?.miDefault === ProfileConfigOption.Visible) {
      this.tabSelected = 2;
    }
    if (profileConfig?.documents === ProfileConfigOption.Editable || profileConfig?.documents === ProfileConfigOption.Visible) {
      this.tabSelected = 3;
    }
    if (profileConfig?.loyalty === ProfileConfigOption.Editable || profileConfig?.loyalty === ProfileConfigOption.Visible) {
      this.tabSelected = 4;
    }
    if (profileConfig?.travelPreferences === ProfileConfigOption.Editable || profileConfig?.travelPreferences === ProfileConfigOption.Visible) {
      this.tabSelected = 5;
    }
    if (profileConfig?.approvalReassignment === ProfileConfigOption.Editable || profileConfig?.approvalReassignment === ProfileConfigOption.Visible) {
      this.tabSelected = 6;
    }
    if (profileConfig?.approvers === ProfileConfigOption.Editable || profileConfig?.approvers === ProfileConfigOption.Visible) {
      this.tabSelected = 7;
    }

  }

  // switch(true){ // commented Hong's changes for now, I think I've solved the tabSelected issues.
  //   case profileConfig?.profile === ProfileConfigOption.Hidden && profileConfig?.address === ProfileConfigOption.Hidden
  //   && profileConfig?.miDefault === ProfileConfigOption.Hidden && profileConfig?.documents === ProfileConfigOption.Hidden
  //   && profileConfig?.loyalty === ProfileConfigOption.Hidden && profileConfig?.travelPreferences === ProfileConfigOption.Hidden:
  //     this.tabSelected = null
  //   break;
  //   case profileConfig?.profile === ProfileConfigOption.Hidden && profileConfig?.address === ProfileConfigOption.Hidden
  //   && profileConfig?.miDefault === ProfileConfigOption.Hidden && profileConfig?.documents === ProfileConfigOption.Hidden
  //   && profileConfig?.loyalty === ProfileConfigOption.Hidden :
  //     this.tabSelected = 5
  //   break;
  //   case profileConfig?.profile === ProfileConfigOption.Hidden && profileConfig?.address === ProfileConfigOption.Hidden
  //   && profileConfig?.miDefault === ProfileConfigOption.Hidden && profileConfig?.documents === ProfileConfigOption.Hidden:
  //     this.tabSelected = 4
  //   break;
  //   case profileConfig?.profile === ProfileConfigOption.Hidden && profileConfig?.address === ProfileConfigOption.Hidden
  //   && profileConfig?.miDefault === ProfileConfigOption.Hidden:
  //     this.tabSelected = 3
  //   break;
  //   case profileConfig?.profile === ProfileConfigOption.Hidden && profileConfig?.address === ProfileConfigOption.Hidden:
  //     this.tabSelected = 2
  //   break;
  //   case profileConfig?.profile === ProfileConfigOption.Hidden:
  //     this.tabSelected = 1
  //   break;
  // }
  // }

  // getUserMiDefaultValuesInput(userMiDefaultValues: UserDefaultManagementInfoValue[]): UserMiDefaultValuesInput[] {
  //   let userMiDefaultValuesInput: UserMiDefaultValuesInput[] = [];
  //   if (userMiDefaultValues.length) {
  //     userMiDefaultValues.forEach((value: UserDefaultManagementInfoValue) => {
  //       if (value.id) {
  //         userMiDefaultValuesInput.push({
  //           managementInfoValue: value.id,
  //           managementInfo: (value.managementInfo.dbId).toString(),
  //           service: value.service.id,
  //           freeTextValue: value.freeTextValue
  //         });
  //       } else {
  //         userMiDefaultValuesInput.push({
  //           managementInfo: (value.managementInfo.dbId).toString(),
  //           service: value.service.id,
  //           freeTextValue: value.freeTextValue
  //         });
  //       }
  //     });
  //     return userMiDefaultValuesInput;
  //   }
  // }

  editUser() {
    let payload: MutationEditUserArgs = {
      id: this.user.id,
    };
    const validTo = this.approvalReassignmentForm.get('validTo')?.value;
    if (validTo) {
      validTo.month -= 1;
    }
    const validFrom = this.approvalReassignmentForm.get('validFrom')?.value;
    if (validFrom) {
      validFrom.month -= 1;
    }
    const approvalPayload = {
      redirectTo: this.approvalReassignmentForm.get('approver')?.value?.id,
      validTo: validTo ? moment(validTo).format('YYYY-MM-DD') : null,
      validFrom: validFrom ? moment(validFrom).format('YYYY-MM-DD') : null
    };

    if (this.profileForm.dirty) {
      payload = { ...payload, ...this.profileForm.value };
      const dob = this.profileForm.get('dob').value;
      if (dob) {
        dob.month -= 1;
      }
      payload.dob = dob ? moment(dob).format('YYYY-MM-DD') : null;

      const additionalEmailArray = [];
      payload.additionalEmail.forEach((email: any) => {
        additionalEmailArray.push(email.value);
      });
      payload.additionalEmail = additionalEmailArray;
    }

    if (this.addressForm.dirty) {
      payload = { ...payload, ...this.addressForm.value };
    }

    if (this.approvalReassignmentForm.dirty) {
      payload = { ...payload, ...this.approvalReassignmentForm.value };
    }

    if (this.docsForm.dirty) {
      payload = { ...payload, ...this.docsForm.value };

      if (payload.documents.length) {
        payload.documents.forEach((document: DocumentInput) => {
          if (document.dateOfExpiry.year) {
            document.dateOfExpiry = Helpers.ngDateToDate(document.dateOfExpiry);
          }
          if (document.dateOfIssue.year) {
            document.dateOfIssue = Helpers.ngDateToDate(document.dateOfIssue);
          }
          if (!document.gender) {
            delete document.gender;
          }
        });
      }
    }

    if (this.loyaltiesForm.dirty) {
      payload = { ...payload, ...this.loyaltiesForm.value };
    }

    if (this.miDefaultForm.dirty) {
      payload = { ...payload, ...this.miDefaultForm.value };
      // payload.userMiDefaultValues = this.getUserMiDefaultValuesInput(payload.userMiDefaultValues as []);
    }

    if (this.travelPreferencesForm.dirty) {
      payload = { ...payload, ...this.travelPreferencesForm.value };
    }

    if (this.approvalForm.dirty) {
      const approvers = this.approvalForm.value;

      if (approvers.userApprovers?.length) {
        let userApproversInput: UserApproverInput[] = [];
        approvers.userApprovers.forEach((value: any, index) => {
          if (value) {
            userApproversInput.push({
              user: this.user.id,
              approverId: value.approverId,
              order: index + 1
            });
          }
        });
        payload.userApprovers = userApproversInput;
      } else {
        payload.userApprovers = [];
      }
    }

    console.log(payload);
    this.userService.editUser(payload).subscribe(async (user: User) => {
      let languageUpdate = false;
      if (this.user.selectedLanguage.id !== user.selectedLanguage.id) {
        languageUpdate = true;
      }
      if (user) {
        this.user = user;
        if (languageUpdate) {
          const selectedLanguage = user.selectedLanguage;
          const availableLanguages = user.availableLanguages.map(lang => lang.isoCode);
          this.languageService.changeLanguageFromUserDetails(selectedLanguage.name, selectedLanguage.isoCode, availableLanguages);
        }
        if (this.approvalReassignmentForm.valid && this.approvalReassignmentForm.dirty) {
          // addRedirectApprover
          if (approvalPayload.redirectTo && approvalPayload.validTo) {
            this.userService.addRedirectApprover(approvalPayload).subscribe((approver: UserApprovalRedirection) => {
              this.approver = approver;
              this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'All changes have been saved' });
            }, () => {
              this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'All changes have not been saved' });
            });
          } else {
            //removeRedirectApprover
            if (this.approver?.id) {
              this.userService.removeRedirectApprover(this.approver.id).subscribe((result: boolean) => {
                this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'All changes have been saved' });
              }, () => {
                this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'All changes have not been saved' });
              });
            }
          }
        } else {
          this.modalService.open(LightningModalTypes.ModalSuccessComponent, { centered: true }, { success: 'All changes have been saved' });
        }
        this.markFormsAsPrestine();
      } else {
        this.modalService.open(
          LightningModalTypes.ModalErrorComponent,
          { centered: true },
          { error: 'All changes have not been saved' });
      }
    });
  }

  refreshToken(): Promise<boolean> {
    return this.authService.checkLogin();
  };

  markFormsAsPrestine(): void {
    this.profileForm.markAsPristine();
    this.addressForm.markAsPristine();
    this.docsForm.markAsPristine();
    this.loyaltiesForm.markAsPristine();
    this.travelPreferencesForm.markAsPristine();
    this.miDefaultForm.markAsPristine();
    this.approvalForm.markAsPristine();
    this.approvalReassignmentForm.markAsPristine();

  };

  checkFormValidation(): boolean {
    return (this.profileForm.dirty && this.profileForm.invalid ||
      this.addressForm.dirty && this.addressForm.invalid ||
      this.docsForm.dirty && this.docsForm.invalid ||
      this.approvalReassignmentForm.dirty && this.approvalReassignmentForm.invalid) ||
      (!this.profileForm.dirty &&
        !this.addressForm.dirty &&
        !this.docsForm.dirty &&
        !this.loyaltiesForm.dirty &&
        !this.travelPreferencesForm.dirty &&
        !this.miDefaultForm.dirty &&
        !this.approvalForm.dirty) &&
      !this.isApprovalReassignmentChange();
  }

  isApprovalReassignmentChange(): boolean {
    if (this.approvalReassignmentForm.dirty) {
      // this only works the first time
      if (!this.approver?.redirectTo && !this.approvalReassignmentForm.get('approver').value?.id &&
        !this.approver?.validTo && !this.approvalReassignmentForm.get('validTo').value) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  clearApprovalReassignment() {
    this.approvalReassignmentForm.controls['approver'].reset();
    this.approvalReassignmentForm.controls['validTo'].reset();
    this.approvalReassignmentForm.markAsPristine();
    this.approvalReassignmentForm.markAsDirty();
  }
}
