<div class="modal-header">
  <span class="hotel_name" id="modal-header"
    ><span i18n="@@ratesHotelLiveRates">Live Rates for</span> {{ hotel.name }}</span
  >
  <button
    class="light-icon-button close"
    (click)="close()"
    aria-label="Hotel Live Rates Close"
    ngbAutoFocus>
    <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
  </button>
</div>
<!-- <app-filter-hotel-user-favorite [hotelName]="hotelName"
                                (changeFilter)="changeFilter()"></app-filter-hotel-user-favorite> -->
<div class="modal-body">
  <div *ngIf="swapSellMessage" class="alert alert-warning alert-dismissible" role="alert">
    <strong
      class="swap-sell-link"
      *ngIf="showSwapSellHotelGroupLink"
      (click)="changeHotelChainFilter()"
      >Click Here!</strong
    >
    {{ swapSellMessage }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="showCtmAccreditation" class="room-rate-row accreditrationArrea">
    <div class="accreditrationText">
      <h3 class="smallHeader" i18n="@@hoterates_accreditation_header1">CTM PARTNERS</h3>
      <h2 class="bold" i18n="@@hoterates_accreditation_header2">Business Ready</h2>
      <p i18n="@@hoterates_accreditation_text">
        This hotel has introduced several measures to ensure the safety and wellbeing of both their
        guests and employees.
      </p>
      <h3 class="bold" i18n="@@hoterates_accreditation_header3t">These measures cover:</h3>
      <ul>
        <li i18n="@@hoterates_accreditation_text_cover1">
          Enhanced cleaning protocols throughout the hotel
        </li>
        <li i18n="@@hoterates_accreditation_text_cover2">Enforced social distancing practices</li>
        <li i18n="@@hoterates_accreditation_text_cover3">PPE where relevant</li>
        <li i18n="@@hoterates_accreditation_text_cover4">Amended food and beverage offering</li>
      </ul>
    </div>
    <div class="accreditrationImage">
      <img src="https://images.sabscorp.com/images/icons/covid19-mask-lg.png" />
    </div>
  </div>

  <div *ngIf="loading">
    <i class="button_spinner"></i>
  </div>

  <div *ngIf="!loading">
    <div class="room-rate-row" *ngFor="let room of rooms.rooms; let last = last; let j = index">
      <div class="rate_container">
        <div class="room_code_display">
          <div class="room-code-display__title-toast">
            <p>
              {{ room.roomType | translate | slice : 0 : getShortRoomType(room)
              }}<span *ngIf="room.rateSource">({{ room.rateSource }})</span>:
            </p>
            <section
              (mouseleave)="tt.close()"
              *ngIf="contains(room.rateDescription, 'ccs')"
              class="container">
              <i
                _ngcontent-c12=""
                class="ico-heart heart-img"
                src="https://images.sabscorp.com/images/sabs2/icons/star.png"
                ngbTooltip="Preferred"
                attr.aria-label="Preferred"
                i18n-ngbTooltip
                triggers="manual"
                #tt="ngbTooltip"
                (mouseenter)="tt.open()"
                placement="bottom"
                (focus)="tt.open()"
                (blur)="tt.close()"
                tabindex="0"
                ><span class="visuallyhidden" i18n="@@Preferred">Preferred</span></i
              >
            </section>
            <div class="rate-description-wrapper">
              <section
                (mouseleave)="tt2.close()"
                class="container"
                *ngIf="hasBreakfast(room.rateDescription, room.roomDescription)">
                <img
                  class="toast-icon"
                  src="https://images.sabscorp.com/images/Corporate/BedandBreakfast1.png"
                  ngbTooltip="Rate may include breakfast"
                  i18n-ngbTooltip
                  alt="Rate may include breakfast"
                  i18n-alt
                  placement="bottom"
                  triggers="manual"
                  #tt2="ngbTooltip"
                  (mouseenter)="tt2.open()"
                  (focus)="tt2.open()"
                  (blur)="tt2.close()"
                  tabindex="0" />
              </section>
              <div class="room-code-display__body">
                <span *ngIf="room.rateDescription !== room.roomType">
                  <span>{{
                    room.rateDescription.length > rateDescriptionMaxLength
                      ? (room.rateDescription | slice : 0 : rateDescriptionMaxLength) + '...'
                      : room.rateDescription
                  }}</span>
                </span>
                <span *ngFor="let item of room.additional?.details">
                  {{ item?.Description }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="room-rate-action-btns">
          <button
            (click)="room.showDetail = !room.showDetail"
            class="rate_select_button"
            id="hotel-rate-select{{ j }}"
            type="button"
            attr.aria-label="Info and add to basket for {{
              getPrice(room) | currency : room.currencyCode : 'symbol'
            }} {{ room.roomType }} {{
              room.rateDescription.length > rateDescriptionMaxLength
                ? (room.rateDescription | slice : 0 : rateDescriptionMaxLength) + '...'
                : room.rateDescription
            }}"
            attr.aria-expanded="{{ room.showDetail ? 'true' : 'false' }}">
            <span i18n="@@dialogTabButtonInfoAnd">Info and </span>
            <span *ngIf="!gmt" i18n="@@dialogTabButtonAddToBasket">add to basket</span>
            <span *ngIf="gmt" i18n="@@dialogTabButtonGmtselect">select</span>
          </button>
        </div>

        <div class="room_price" *ngIf="room.nights.length >= 2; else oneNightBlock">
          <span
            >{{ getPrice(room) | currency : room.currencyCode : 'symbol' }}
            <span *ngIf="showPricePerRoomPerNight()"
              >/
              {{ getPricePerRoomPerNight(room) | currency : room.currencyCode : 'symbol' }}
              <span i18n="@@itemHotelsPRPN">prpn</span>
            </span>
          </span>
          <br />
          <span
            *ngIf="room.currencyConversion?.convertedCode !== room.currencyConversion?.localCode">
            &nbsp;(
            <span i18n="@@ratesHotelApproxRate">Approx</span>:
            {{
              room.currencyConversion.convertedTotal
                | currency : room.currencyConversion.convertedCode
            }}
            <ng-container *ngIf="showPricePerRoomPerNight()">
              /
              {{
                getConvertedPricePerRoomPerNight(room)
                  | currency : room.currencyConversion.convertedCode
              }}
            </ng-container>
            )
          </span>
          <a
            class="room_price_breakdown"
            (click)="togglePriceBreakdown(room)"
            (keydown.enter)="togglePriceBreakdown(room)"
            tabindex="0"
            >
              <span i18n="@@showPriceBreakdown" *ngIf="!displayPriceBreakdown[room.roomId]">Show Price Breakdown</span>
              <span i18n="@@hidePriceBreakdown" *ngIf="displayPriceBreakdown[room.roomId]">Hide Price Breakdown</span>
              <span class="sr-only">
                 - {{getPrice(room) | currency : room.currencyCode : 'symbol'}} {{ room.roomType }} {{room.rateDescription.length > rateDescriptionMaxLength}}
              </span>
            </a>
        </div>

        <ng-template #oneNightBlock class="room_price">
          <span
            >{{ getPrice(room) | currency : room.currencyCode : 'symbol' }}
            <span *ngIf="showPricePerRoomPerNight()"
              >/
              {{ getPricePerRoomPerNight(room) | currency : room.currencyCode : 'symbol' }}
              <span i18n="@@itemHotelsPRPN">prpn</span>
            </span>
          </span>
          <br />
          <span
            *ngIf="room.currencyConversion?.convertedCode !== room.currencyConversion?.localCode">
            (
            <span i18n="@@ratesHotelApproxRate">Approx</span>:
            {{
              room.currencyConversion.convertedTotal
                | currency : room.currencyConversion.convertedCode
            }}
            )
          </span>
        </ng-template>
        <div class="message_div">
          <app-warning-tags [ticket]="room" [displayCheck]="true"></app-warning-tags>
        </div>
      </div>
      <app-price-breakdown-dialog [room]="room" [hotel]="hotel" *ngIf="displayPriceBreakdown[room.roomId]"></app-price-breakdown-dialog>
      <div *ngIf="room.showDetail" class="room_details">
        <span
          class="conidtions_header"
          (click)="room.showDetail = false"
          role="button"
          i18n="@@ratesHotelBookingConditions"
          >Booking conditions:</span
        >
        <div class="rate_source_warning" *ngIf="warnAboutSource(room.rateSource)">
          This is a {{ room.rateSource }} rate, please read the rate rules carefully
        </div>

        <div>
          <h4 class="condition_title" i18n="@@rateInformation">Rate Information:</h4>
          <span>
            {{ room.roomType }}<span *ngIf="room.rateSource">({{ room.rateSource }})</span>:
          </span>
          <span>{{ room.rateDescription }}</span>
          <span *ngFor="let item of room.additional?.details">
            {{ item?.Description }}
          </span>
        </div>

        <div *ngFor="let cond of listOfHotelPolicies">
          <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.cancellation">
            <h4 class="condition_title room_error">
              <span i18n="@@cancellationPolicy">Cancellation Policy:</span>
              <i
                tabindex="0"
                class="ico-exclaim-1 ico-exclaim-1--strong cancellation-tooltip-icon ng-star-inserted"
                aria-label="Cancellation policy: {{ room.policies[cond] }}">
                <div class="cancellation-tooltip">
                  <div class="cancellation-tooltip-arrow"></div>
                  <span i18n="@@cancellationPolicy">Cancellation Policy:</span>
                    @if(hotel.source === ServiceProvider.TravelportHotels){
                      <span i18n="@@cancellationDealine">Cancellation Deadline:</span>
                      {{room.policies[cond]| date: 'dd/MM/yyyy HH:mm'}}
                    } @else{
                      {{ room.policies[cond] }}
                    }
                </div>
              </i>
            </h4>
            <span class="room_error">
              @if(hotel.source === ServiceProvider.TravelportHotels){
                <span i18n="@@cancellationDealine">Cancellation Deadline:</span>
                {{room.policies[cond] | date: 'dd/MM/yyyy HH:mm'}}
              } @else{
                {{ room.policies[cond] }}
              }
            </span>
          </div>
        </div>

        <div *ngIf="room.roomDescription">
          <h4 class="condition_title" i18n="@@roomInformation">Room Information:</h4>
          <span>{{ room.roomDescription }}</span>
        </div>

        <div *ngFor="let cond of listOfHotelPolicies">
          <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.general">
            <h4 class="condition_title" i18n="@@generalPolicies">General Policies:</h4>
            <span>{{ room.policies[cond] }}</span>
          </div>
        </div>
        <div *ngFor="let cond of listOfHotelPolicies">
          <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.disabledAccess">
            <h4 class="condition_title" i18n="@@disabledAccessPolicy">Disabled Access Policy:</h4>
            <span>{{ room.policies[cond] }}</span>
          </div>
        </div>

        <!-- Rate Code start -->
        <div *ngIf="room.rateCode && hotel.source === ServiceProvider.Confirma">
          <h4 class="condition_title" i18n="@@rateCode">Rate Code:</h4>
          <span>{{ room.rateCode }}</span>
        </div>
        <div *ngIf="room.additional?.productCode && hotel.source === ServiceProvider.SabreCSL">
          <h4 class="condition_title" i18n="@@rateCode">Rate Code:</h4>
          <span>{{ room.additional?.productCode }}</span>
        </div>
        <!-- Rate Code end -->

        <div *ngIf="hotel.source">
          <h4 class="condition_title" i18n="@@rateProvider">Rate Provider:</h4>
          <span>{{ hotel.source }}</span>
        </div>

        <div *ngFor="let cond of listOfHotelPolicies">
          <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.guarantee">
            <h4 class="condition_title" i18n="@@guaranteePolicy">Guarantee Policy:</h4>
            <span>{{ room.policies[cond] }}</span>
          </div>
          <div *ngIf="room.policies[cond] && cond === HotelPolicyEnum.deposit">
            <h4 class="condition_title" i18n="@@depositPolicy">Deposit Policy:</h4>
            <span>{{ room.policies[cond] }}</span>
          </div>
        </div>
        <div class="flex-row-container select-rate-btn">
          <div *ngIf="gmt; else searchHotelItem">
            <button
              (click)="selectNewRoomIdGMT(j, gmtSelectedRoomIndex !== j)"
              [disabled]="room.unavailable && !canOverride"
              class="small-button"
              id="hotel-selected-{{ j }}"
              attr.aria-label="Select {{ roomPrices[room.roomId] | currency : room.currencyCode : 'symbol' }} {{
                room.roomType
              }} to bolt"
              attr.aria-expanded="{{ room.showDetail }}"
              tabindex="0"
              >
              {{ gmtSelectedRoomIndex === j ? 'Deselect' : 'Select' }}
            </button>
          </div>
          <ng-template #searchHotelItem>
            <button
              (click)="addToCart(room)"
              [disabled]="room.unavailable && !canOverride"
              class="small-button"
              id="hotel-add-to-basket-{{ j }}"
              attr.aria-label="Add {{ getPrice(room) | currency : room.currencyCode : 'symbol' }} {{
                room.roomType
              }} to basket"
              attr.aria-expanded="{{ room.showDetail }}"
              tabindex="0"
              i18n="@@addToBasket">
              Add to Basket
            </button>
          </ng-template>
        </div>
        <div *ngIf="room.errorConditions && !room.loadingConditions">
          <span class="room_error" i18n="@@ratesHotelErrorLoading"
            >Error loading room conditions.</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

