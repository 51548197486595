<ng-template #railairtemp let-r="result" let-t="term">
    <div class="light-flex">
        <i class="custom_icon-eurostar item-icon" *ngIf="!r.group"></i>
        <span *ngIf="r.group" class="multiairportgrouping">
            <i class="custom_icon-eurostar"></i>
        </span>
        <span class="item-code">{{ r.code }}</span>
        <span class="item-dest">{{ r.destination | flightFormat }} ({{r.gateway}})</span>
    </div>
</ng-template>

<div class="eurostar-search-container">
    <div>
        <app-light-toggle-group tabindex="-1">
            <app-light-toggle-btn
                [selected]="searchParams.chosenSearchType === EurostarSearchType.oneWay"
                (click)="searchParams.chosenSearchType = EurostarSearchType.oneWay"
                iconClass="ico-oneway"
                tabindex="-1"
                i18n="@@eurostarSearchOneWayOnly"
                >One Way Only</app-light-toggle-btn
            >
            <app-light-toggle-btn
                [selected]="searchParams.chosenSearchType === EurostarSearchType.return"
                (click)="searchParams.chosenSearchType = EurostarSearchType.return"
                iconClass="ico-return"
                tabindex="-1"
                i18n="@@eurostarSearchReturnTrip"
                >Return trip
            </app-light-toggle-btn>
        </app-light-toggle-group>
    </div>
    <div class="half-container flex-row-container">
        <div class="half-block">
            <label
                id="outboundJourney"
                class="header-label"
                for="euro-outbound-journey"
                i18n="@@eurostarSearchOutboundJourney"
                >Outbound Journey
            </label>
            <i class="custom_icon-eurostar combo-box-icon"></i>
            <input
                #ofd
                id="euro-outbound-journey"
                autocomplete="off"
                name="outboundJourney"
                placeholder="Depart from..."
                i18n-placeholder
                type="text"
                class="combo-box"
                [(ngModel)]="searchParams.departLocation"
                (selectItem)="checkHotelCheckbox()"
                [ngbTypeahead]="getOutStations"
                (click)="outboundClick$.next($event.target.value)"
                [resultTemplate]="railairtemp"
                [inputFormatter]="formatter_rail_air"
                aria-labelledby="outboundJourney"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please add in a departure location")}'  />
            <i *ngIf="loadingOutbound" class="button_spinner input-loading-spinner-left"></i>
        </div>
        <div class="half-block">
            <label
                id="inboundJourney"
                class="header-label"
                for="eurostar-inbound-journey"
                i18n="@@eurostarSearchInboundJourney"
                >Inbound Journey
            </label>
            <i class="custom_icon-eurostar combo-box-icon"></i>
            <input
                #ofa
                id="eurostar-inbound-journey"
                autocomplete="off"
                name="inboundJourney"
                placeholder="Depart from..."
                i18n-placeholder
                type="text"
                class="combo-box"
                [(ngModel)]="searchParams.arriveLocation"
                (selectItem)="checkHotelCheckbox()"
                [ngbTypeahead]="getInStations"
                (click)="inboundClick$.next($event.target.value)"
                [resultTemplate]="railairtemp"
                [inputFormatter]="formatter_rail_air"
                aria-labelledby="inboundJourney"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please add in a arrival destination")}'  />
            <i *ngIf="loadingInbound" class="button_spinner input-loading-spinner-left"></i>
        </div>
    </div>
    <ng-container
        *ngIf="{
            criteria: (searchParams.timeCriteriaOptions | async) || []
        } as data">
        <div class="half-container flex-row-container">
            <div class="half-block">
                <div class="flex-row-container">
                    <ngbd-datepicker-i18n>  
                        <div class="flex-column-container combo-box-left">
                            <label class="header-label date-label" i18n="@@eurostarSearchOutboundJourneyDate" for="euro-outbound-date"
                                >Outbound Date (DD/MM/YYYY)</label
                            >
                            <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                            <app-datepicker
                                [minDate]='searchParams.minimumOutBoundDate'
                                [startDate]='searchParams.departDate'
                                [model]="searchParams.departDate"
                                [class]="'combo-box'"
                                [componentId]="'euro-outbound-date'"
                                (newDate)="searchParams.departDate = $event; checkHotelCheckbox();"
                                >
                                </app-datepicker>
                        </div>
                        <div class="flex-column-container select-box-right">
                            <label class="header-label" i18n="@@eurostarSearchOutboundJourneyTime" for="eurostar-outbound-time"
                                >Outbound Time</label
                            >
                            <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                            <select
                                #oft
                                id="eurostar-outbound-time"
                                class="select-box"
                                [ngClass]="{
                                    time_disabled: searchParams.outTimeCriteria === TimeWindow.Anytime
                                }"
                                [(ngModel)]="searchParams.departTime"
                                [disabled]="searchParams.outTimeCriteria === TimeWindow.Anytime"
                                tabindex="0">
                                <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                                    {{ time.display }}
                                </option>
                            </select>
                        </div>
                    </ngbd-datepicker-i18n>  
                </div>
                <div class="time-criteria-container">
                    <span
                        class="time-criteria"
                        *ngIf="searchParams.outTimeCriteria === TimeWindow.Arrive"
                        i18n="@@EurostarPlusMinus2Hours"
                        >± 2hrs
                    </span>
                </div>
                <div
                    class="flex-row-container flex-row-container--right flex-row-container--tb-margin flex-row-container--reset">
                    <span
                        id="search-eurostar-time-preferences"
                        hidden
                        i18n="@@AriaEurostarTimePreferences"
                        >Time preferences</span
                    >
                    <mat-radio-group
                        [(ngModel)]="searchParams.outTimeCriteria"
                        labelPosition="after"
                        aria-label="outbound journey time preferences"
                        class="triple-radio-buttons">
                        <mat-radio-button
                            class="light-primary"
                            color="primary"
                            [value]="crit"
                            *ngFor="let crit of data.criteria"
                            id="euro-outbound-timepref-{{crit}}">
                            {{ crit | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="half-block" *ngIf="searchParams.isReturn">
                <div class="flex-row-container">
                    <ngbd-datepicker-i18n>  
                        <div class="flex-column-container combo-box-left">
                            <label class="header-label date-label" i18n="@@eurostarSearchReturnJourneyDate" for="euro-return-date"
                                >Return Date (DD/MM/YYYY)</label
                            >
                            <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                            <app-datepicker
                                [minDate]='searchParams.departDate'
                                [startDate]='searchParams.returnDate'
                                [model]="searchParams.returnDate"
                                [class]="'combo-box'"
                                [componentId]="'euro-return-date'"
                                (newDate)="searchParams.returnDate = $event; checkHotelCheckbox();"
                                >
                            </app-datepicker>
                        </div>
                        <div class="flex-column-container select-box-right">
                            <label class="header-label" i18n="@@eurostarSearchReturnJourneyTime" for="eurostar-return-time"
                                >Return Time</label
                            >
                            <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                            <select
                                class="select-box"
                                id="eurostar-return-time"
                                [ngClass]="{
                                    time_disabled: searchParams.inTimeCriteria === TimeWindow.Anytime
                                }"
                                [(ngModel)]="searchParams.arriveTime"
                                [disabled]="searchParams.inTimeCriteria === TimeWindow.Anytime"
                                tabindex="0">
                                <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                                    {{ time.display }}
                                </option>
                            </select>
                        </div>
                    </ngbd-datepicker-i18n>  
                </div>
                <div class="time-criteria-container">
                    <span
                        class="time-criteria"
                        *ngIf="searchParams.inTimeCriteria === TimeWindow.Arrive"
                        i18n="@@EurostarPlusMinus2Hours"
                        >± 2hrs
                    </span>
                </div>
                <div
                    class="flex-row-container flex-row-container--between flex-row-container--tb-margin flex-row-container--reset"
                    *ngIf="searchParams.chosenSearchType === EurostarSearchType.return">
                    <span
                        id="search-eurostar-return-prefs"
                        hidden
                        i18n="@@SearchEurostarReturnPrefs"
                        >Return preferences</span
                    >
                    <mat-radio-group
                        [(ngModel)]="searchParams.inTimeCriteria"
                        labelPosition="after"
                        aria-labelledby="search-eurostar-return-prefs"
                        class="triple-radio-buttons timecrit-buttons">
                        <mat-radio-button
                            class="light-primary"
                            color="primary"
                            [value]="crit"
                            *ngFor="let crit of data.criteria"
                            id="euro-return-timepref-{{crit}}">
                            {{ crit | translate }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container
        *ngIf="{
            avaliableClasses: (searchParams.avaliableClasses | async) || []
        } as data">
        <div class="flex-row-container flex-row-container--right">
            <button
                *ngIf="resultsMode"
                (click)="search()"
                class="large-button large-button--search"
                i18n="@@eurostarSearchButton">
                Search
            </button>
        </div>
        <div class="flex-row-container">
            <h2 i18n="@@eurostarSearchOptions" class="rem-1-5">Options</h2>
        </div>
        <div class="half-container">
            <div class="half-block">
                <label
                    class="header-label"
                    id="PreferredClassO"
                    i18n="@@eurostarSearchChooseClassOut"
                    for="eurostar-class-outbound"
                    >Choose Preferred Class Outbound</label
                >
                <section (mouseleave)="tt.close()" class="container">
                    <i
                        class="ico-info fare-rules-ico"
                        (click)="openFareRulesAndFocus()"
                        (keydown.enter)="openFareRulesAndFocus()"
                        (keydown)="openFareRulesWithSpace($event)"
                        ngbTooltip="For more information regarding the fare conditions select here"
                        placement="top"
                        aria-label="For more information regarding the fare conditions select here"
                        alt="For more information regarding the fare conditions select here"
                        i18n-ngbTooltip
                        i18n-aria-label
                        tabindex="0"
                        role="button"
                        triggers="manual"
                        #tt="ngbTooltip"
                        (mouseenter)="tt.open()"
                        (focus)="tt.open()"
                        (blur)="tt.close()"
                        tabindex="0"
                        id="fare-rules-icon"
                        ><span class="visuallyhidden"
                            >For more information regarding the fare conditions select here</span
                        ></i
                    >
                </section>
                <select
                    [(ngModel)]="searchParams.cabinClass"
                    class="select-box select-box--no-icon"
                    aria-labelledby="PreferredClassO"
                    id="eurostar-class-outbound">
                    <option *ngFor="let class of data.avaliableClasses" [value]="class.type">
                        {{ class.name | translate }}
                    </option>
                </select>
            </div>
            <div class="half-block">
                <ng-container *ngIf="searchParams.chosenSearchType === EurostarSearchType.return">
                    <label
                        class="header-label"
                        id="PreferredClassI"
                        i18n="@@eurostarSearchChooseClassIn"
                        for="eurostar-class-inbound"
                        >Choose Preferred Class Inbound</label
                    >
                    <select
                        [(ngModel)]="searchParams.returnCabinClass"
                        class="select-box select-box--no-icon"
                        aria-labelledby="PreferredClassI"
                        id="eurostar-class-inbound">
                        <option *ngFor="let class of data.avaliableClasses" [value]="class.type">
                            {{ class.name | translate }}
                        </option>
                    </select>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div class="flex-column-container flex-column-container--tb-margin">
        <mat-checkbox
            class="light-primary"
            color="primary"
            [checked]="searchParams.maxConnections === 0"
            #checked
            id="euro-direct-only"
            (change)="direct(checked.checked)"
            (keydown.enter)="direct(checked)"
            i18n="@@eurostarSearchDirectOnly">
            Direct Only
        </mat-checkbox>
    </div>
    <div class="flex-row-container hotel-check" *ngIf="searchParams.chosenSearchType === EurostarSearchType.return && displayHotelCheck && toggleHotelSearchRequested.observed">
        <button
            class="large-button"
            color="primary"
            [attr.aria-pressed]="hotelsearchRequested"
            id="car-checkbox-hotel-{{hotelsearchRequested ? 'remove' : 'add'}}"
            (click)="toggleHotelCheckbox()">
            <span i18n="@@eurostarAddHotelcheck" *ngIf="!hotelsearchRequested">
                Add Hotel
            </span>
            <span i18n="@@eurostarRemoveHotelcheck" *ngIf="hotelsearchRequested">
                Remove Hotel
            </span>
        </button>
    </div>
    <ng-container *ngIf="hotelsearchRequested && toggleHotelSearchRequested.observed && searchParams.chosenSearchType === EurostarSearchType.return">
        <h2 class="hotel-header">Hotel</h2>
        <app-hotel-search></app-hotel-search>
      </ng-container>
    <app-eurostar-loading-screen></app-eurostar-loading-screen>
</div>
