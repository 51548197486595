<ng-container
  *ngIf="{
    results: (searchParams.results | async) || [],
    isvalid: searchParams.isValid | async,
    messages: searchParams.messages | async,
    cheapest: searchParams.cheapest | async,
    originalUserSearch: this.searchParams.originalUserSearch,
    fullWidth:
      this.searchParams.originalUserSearch.chosenSearchType === RailJourneyType.SingleJourney ||
      this.searchParams.originalUserSearch.chosenSearchType === RailJourneyType.OpenReturnJourney
  } as data">
  <div>
    <h2 class="search_content_titles" i18n="@@rail_searchCriteria">Search Criteria</h2>
    <a
      href="#trains-link"
      (click)="skipToResultContent()"
      role="link"
      class="sr-only sr-only-focusable"
      >Skip to Result Content</a
    >
    <app-invoked-user></app-invoked-user>
  </div>
  <div *ngIf="!(searchParams.isLoading | async)">
    <!-- search form -->
    <div class="rail_search_content background-60">
      <app-search-toggle>
        <app-validate-errors [messages]="data.messages"></app-validate-errors>
        <a
          href="javascript:void(0)"
          (click)="skipToResultContent()"
          class="sr-only sr-only-focusable"
          >Skip to Result Content</a
        >
        <app-rail-search></app-rail-search>
        <div class="flex-row-container flex-row-container--right">
          <button
            *ngIf="showDebug"
            class="large-button large-button--search"
            (click)="alwaysLog(data.results[0])">
            Dump
          </button>
          <button
            i18n-title
            class="large-button large-button--search"
            title="Start another search"
            (click)="reSearch()"
            i18n="@@railSearch">
            Search
          </button>
        </div>
      </app-search-toggle>
      <app-risk-alerts [countryCode]="'GB'"></app-risk-alerts>
    </div>
    <div
      id="trains-link"
      class="rail_search_results"
      *ngIf="data.results[0] && data.originalUserSearch">
      <div class="flex-row-container carbon_rail_container">
        <button
          class="large-button"
          *ngIf="flightSearchParams.railFromFlightSearchPerformed"
          (click)="switchToFlight()">
          Back to flights
        </button>
        <app-carbon-comparison *ngIf="flightSearchParams.railFromFlightSearchPerformed">
        </app-carbon-comparison>
      </div>

      <app-announcements
          [basketStatus]="0"
          page="results">
      </app-announcements>

      <div class="flex-row-container switchrail_container">
        <button
          *ngIf="searchService.searches[ServiceType.Hotel]?.cheapestPrice?.amount"
          (click)="switchToHotel()"
          class="small-button add-hotel-to-search-button"
          id="railresults-button-switchtoHotel">
          <i class="ico-bed" aria-hidden="true"></i>
          <span i18n="@@railHotelsFrom">Hotels From:</span>&nbsp;
          {{
            searchService.searches[ServiceType.Hotel].cheapestPrice.amount
              | currency : searchService.searches[ServiceType.Hotel].cheapestPrice.currencyCode
          }}
        </button>
      </div>

      <div
        class="search_results_header"
        *ngIf="data.results[0]?.outbound.length || data.results[0]?.return.length">
        <h2
          class="search_results_page_title margin-bottom-20"
          *ngIf="!data.originalUserSearch.isAirportExpress">
          <span i18n="@@railTrainsFor">Trains for</span>
          {{ data.originalUserSearch?.travellingFrom?.name | titlecase }}
          <i class="ico-breadcrumb-arrow" aria-hidden="true"></i>
          {{ data.originalUserSearch?.travellingTo?.name | titlecase }}
          <span *ngIf="data.originalUserSearch?.viaLocation?.name"
            >&nbsp;(Via {{ data.originalUserSearch?.viaLocation?.name | titlecase }})</span
          >
        </h2>
        <h2
          class="search_results_page_title margin-bottom-20"
          *ngIf="data.originalUserSearch.isAirportExpress">
          {{ data.originalUserSearch.airportExpressStation | airportExpress }}
        </h2>
        <div class="cheapest_fares_container">
          <h2 i18n="@@railTrainsCheapestFares">Cheapest Fares:</h2>

          <app-cheapest-button
            *ngIf="
              data.originalUserSearch.chosenSearchType === RailJourneyType.ReturnJourney &&
              data.cheapest.outboundCheapest.returnJourneyFares.price &&
              data.cheapest.cheapestClassTicketReturn.standardClassFare.price
            "
            (hightLight)="highlightCheapestTickets(['outbound-' + $event, 'inbound-' + $event])"
            (keydown)="resolveTabbingHighlight()"
            [price]="[
              data.cheapest.outboundCheapest.returnJourneyFares.price,
              data.cheapest.cheapestClassTicketReturn.standardClassFare.price
            ]"
            i18n-message
            message="Returns From: ">
          </app-cheapest-button>
          <app-cheapest-button
            *ngIf="data.originalUserSearch.chosenSearchType === RailJourneyType.SingleJourney"
            (hightLight)="highlightCheapestTickets(['outbound-' + $event])"
            (keydown)="resolveTabbingHighlight()"
            [price]="[
              data.cheapest.outboundCheapest.singleJourneyFares.price,
              data.cheapest.cheapestClassTicket.standardClassFare.price
            ]"
            i18n-message
            message="Singles From: ">
          </app-cheapest-button>
          <app-cheapest-button
            *ngIf="
              data.cheapest.cheapestClassTicketDual &&
              data.originalUserSearch.chosenSearchType === RailJourneyType.ReturnJourney
            "
            (hightLight)="
              highlightCheapestTickets([
                'outbound-' + getCheapestDualOutbound(data.cheapest),
                'inbound-' + getCheapestDualInbound(data.cheapest)
              ])
            "
            (keydown)="resolveTabbingHighlight()"
            [price]="[
              data.cheapest.outboundCheapest.dualSingleJourneyFares.price +
                data.cheapest.inboundCheapest.dualSingleJourneyFares.price,
              data.cheapest.cheapestClassTicketDual.standardClassFare.price +
                data.cheapest.cheapestClassTicketDual.inboundStandardClassFare.price
            ]"
            i18n-message
            message="Two Singles From: ">
          </app-cheapest-button>
          <app-cheapest-button
            *ngIf="
              data.cheapest.outboundCheapest.splitFares.price > 0 &&
              data.originalUserSearch.chosenSearchType === RailJourneyType.ReturnJourney
            "
            (hightLight)="highlightCheapestTickets(['outbound-' + $event, 'inbound-' + $event])"
            (keydown)="resolveTabbingHighlight()"
            [price]="[data.cheapest.outboundCheapest.splitFares.price]"
            i18n-message
            message="Split Fares From: ">
          </app-cheapest-button>
        </div>
      </div>
      <div
        *ngIf="
          !data.fullWidth && data.results[0]?.outbound.length && data.results[0]?.return.length
        "
        class="flex-row-container rail_results_hint"
        i18n="@@SelectIntrsuctions">
        Select one price option from the Outbound table and one option from the Return table.
      </div>

      <div
        class="search_results_items"
        *ngIf="data.results[0]?.outbound.length || data.results[0]?.return.length">
        <app-rail-results-block
          [sourceId]="data.results[0].sourceId"
          class="rail_search_results_outbound"
          direction="outbound"
          [ngClass]="{
            full_width: data.fullWidth
          }"
          [expandedTickets]="expandedTickets"
          [fareTypes]="makeFareNames | memoize : data.results[0].outbound"
          [from]="
            data.originalUserSearch.travellingFrom
              ? (data.originalUserSearch.travellingFrom.name | titlecase)
              : (data.results[0].outbound[0].from | titlecase)
          "
          [to]="
            data.originalUserSearch.travellingTo
              ? (data.originalUserSearch.travellingTo.name | titlecase)
              : (data.results[0].outbound[0].to | titlecase)
          "
          (earlier)="
            earlier($event, data, 'outbound')
          "
          (later)="
            getNextResultsPage(
              data.results[0].outbound[getFinalDisplayedResultIndex(data.results[0].outbound)]
                .departDateTime,
              RailSearchCriteria.Depart,
              data.results[0].outbound[0],
              data.results[0].outbound[getFinalDisplayedResultIndex(data.results[0].outbound)],
              EarlierOrLater.LATER
            )
          "
          (prevDay)="getDifferentDayPage(EarlierOrLater.EARLIER, false)"
          (nextDay)="getDifferentDayPage(EarlierOrLater.LATER, false)"
          [date]="data.originalUserSearch.outBoundDateTime.format() | sabsDate : 2"
          [journeys]="data.results[0].outbound"></app-rail-results-block>
        <app-rail-results-block
          [sourceId]="data.results[0].sourceId"
          class="rail_search_results_return"
          *ngIf="!data.fullWidth"
          direction="inbound"
          [expandedTickets]="expandedTickets"
          [fareTypes]="makeFareNames | memoize : data.results[0].outbound"
          [to]="
            data.originalUserSearch.travellingFrom
              ? (data.originalUserSearch.travellingFrom.name | titlecase)
              : (data.results[0].return[0].to | titlecase)
          "
          [from]="
            data.originalUserSearch.travellingTo
              ? (data.originalUserSearch.travellingTo.name | titlecase)
              : (data.results[0].return[0].from | titlecase)
          "
          (earlier)="
            earlier($event, data, 'return', true)
          "
          (later)="
            getNextResultsPage(
              data.results[0].return[getFinalDisplayedResultIndex(data.results[0].return)]
                .departDateTime,
              RailSearchCriteria.Depart,
              data.results[0].return[0],
              data.results[0].return[getFinalDisplayedResultIndex(data.results[0].return)],
              EarlierOrLater.LATER,
              true
            )
          "
          (prevDay)="getDifferentDayPage(EarlierOrLater.EARLIER, true)"
          (nextDay)="getDifferentDayPage(EarlierOrLater.LATER, true)"
          [date]="data.originalUserSearch.inBoundDateTime.format() | sabsDate : 2"
          [journeys]="data.results[0].return"
          [outboundJourneys]="data.results[0].outbound"></app-rail-results-block>
      </div>
    </div>
    <div
      class="no_results_message background-10" role="status"
      *ngIf="
        data.results.length === 0 ||
        data.results[0]?.outbound.length === 0 ||
        (data.results[0]?.outbound.length === 0 && data.results[0]?.return.length === 0)
      ">
      <h2 class="no_results_message_heading"><i class="ico-norail no_results_icon" aria-hidden="true"></i>Oh, no!</h2>
      <p>
        <span i18n="@@railCouldntFind">
          We couldn't find any rail journeys that match your criteria. Try
        </span>
        <strong i18n="@@railCouldntFindB">updating your search</strong>.
      </p>
      <p i18n="@@railContactConsultant">
        If you can't find exactly what you're looking for, contact a consultant.
      </p>
    </div>
  </div>
</ng-container>
