<div class="progress_list_wrapper secondary-text-colour">
  <div class="progress_list">
    <ng-container
      *ngFor="
        let searchObject of page === pageType.Itinerary || page === pageType.GetMeThere
          ? basketItemTypes
          : (searchService.search_objects | selectedSearchObject);
        let last = last
      ">
      <div
        class="progress_list_item"
        aria-current="step"
        [class.active-search-type]="(searchObject | replaceUnderscore | lowercase) === resultsType">
        <div>
          <ng-container *ngIf="getIconClass | memoize : searchObject as iconClass">
            <i aria-hidden="true" class="travel_icon {{ iconClass }}"></i>
          </ng-container>
          <span
            class="travel_icon_txt"
            *ngIf="searchObject !== 'airportexpress' && page !== pageType.GetMeThere"
            >{{ searchObject | replaceUnderscore | titlecase }}</span
          >
          <span
            class="travel_icon_txt"
            *ngIf="searchObject === 'airportexpress'"
            i18n="@@resultsHeaderAirportExpress"
            >Airport Express</span
          >
          <div
            class="icon-separator"
            aria-hidden="true"
            [style.display]="last && !valid ? 'none' : 'block'"
            [ngClass]="{ 'gmt-icon-separator': page === pageType.GetMeThere }"></div>
        </div>
      </div>
    </ng-container>
    <div
      class="progress_list_item progress_list_item--confirm"
      *ngIf="valid"
      aria-current="step"
      [class.active-search-type]="page === pageType.Itinerary">
      <div>
        <i class="ico-check-1 travel_icon" aria-hidden="true"></i>
        <span
          class="travel_icon_txt"
          *ngIf="page !== pageType.GetMeThere"
          i18n="@@resultsHeaderConfirm"
          >Confirm</span
        >
      </div>
    </div>
  </div>
</div>
